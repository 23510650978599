export function tong(a, b) {
    return a + b;
  }


  /* function xỷ lý tiền ngăn cách bởi dấu chấm */
  export function formatCurrency(value) {
    // Chuyển đổi chuỗi số thành số nguyên
    const numberValue = parseInt(value, 10);
  
    // Kiểm tra nếu numberValue là một số hợp lệ
    if (!isNaN(numberValue)) {
      // Định dạng số thành chuỗi với ngăn cách hàng ngàn
      const formattedValue = numberValue.toLocaleString('vi-VN');
      return formattedValue;
    }
  
    // Trả về chuỗi gốc nếu không thể định dạng
    return value;
  }