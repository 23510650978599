export const Clean = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
      enableBackground="new 0 0 100 100"
      xmlSpace="preserve"
      preserveAspectRatio="none"
      width="30px"
      height="30px"
      className=""
      fill="rgba(0, 102, 171, 1)"
      {...props}
    >
      <g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M90.758,61.323c-0.511-1.911-1.969-3.536-1.68-5.28   c-0.377,0.145-1.289-0.961-0.72-1.68c-0.434,0.318-0.912-0.23-0.72-1.2c-0.576,0.174-0.642-0.951-1.681-0.96   c-0.061-1.135-0.099-1.578-0.239-2.16c-0.641-1.46-3.095-0.356-2.16-1.92c-2.552-0.918-3.267,0.294-4.561-0.96   c-4.323,0.835-9.55,2.458-12,5.28c-0.847-0.071,0.848-0.649,0-0.721c-0.791,0.09-1.199,0.562-1.68,0.96   c-8.453-0.126-19.685,0.083-26.4,0.24c0.987,13.779,3.299,29.206,5.52,43.44c0.562,0.637,2.577-0.178,3.12,0.479   c0.72,0,1.44,0,2.16,0c7.041-0.56,15.12-0.08,22.56-0.24c1.253-4.905,1.421-13.765,2.64-20.159   c5.544-2.057,9.479-5.722,17.761-5.04C92.34,68.322,91.645,64.64,90.758,61.323z M69.639,93.483c-3.51,0.141-5.828-0.782-9.12-0.24   c0.021-0.341,0.039-0.68-0.24-0.72c0.049,1.069-2.25-0.146-2.88,0.72c-0.407-0.156-0.15-0.552,0.24-0.48   c-0.655-0.068-0.829-0.375-1.92-0.479c-0.217,0.213-0.02,0.73-0.961,0.72c0.181-0.453,0.717-0.166,0.24-0.72   c-0.842,0.558-1.205,0.188-2.16,0.72c-0.417-1.918-4.853,1.215-5.52-1.92c0.193,0.127,0.39,0.25,0.72,0.24   c-1.341-9.736-2.735-17.999-3.36-24.24c8.555,0.324,16.531-0.677,28.08-0.48C72,74.893,70.779,84.779,69.639,93.483z    M72.998,63.003c-1.653-0.147-1.811,0.378-2.88-0.72c-0.224,0.176-0.363,0.437-0.479,0.72c-0.272-0.046-0.134-0.24,0-0.24   c-1.242-0.232-5.141-1.018-6.96,0.48c-0.039-0.507-0.214-0.682-0.721-0.72c-5.85,0.39-12.345,0.097-17.52,0.479   c-0.74-1.58-0.8-3.84-0.96-6c7.61-0.716,19.371-0.299,30.72-0.479C74.15,59.035,73.32,60.765,72.998,63.003z M84.278,62.763   c-3.804-0.344-1.674,5.069-0.48,6.48c-2.374,0.636-5.459,2.286-8.159,3.359c0.253-5.139,1.205-13.003,2.159-18.96   c-0.619-0.58-2.443,0.044-3.119-0.479c1.142-0.944,1.87-0.208,2.64-0.24c0.542-0.134-0.291-0.374,0.24-0.72   c0.34,0.49,0.896,0.367,1.199-0.24c1.496,0.47,3.25,1.355,4.561,2.64c-0.194-0.045-0.239-0.24-0.48-0.239   c2.18,4.479,4.389,8.114,5.28,13.439C86.146,67.322,86.682,62.98,84.278,62.763z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M25.718,73.562c-0.662-10.405,1.092-21.228,0-30.24   c1.384-2.577,0.872-4.556,0.24-6.72c0.346,0.054,0.083,0.716,0.48,0.72c-0.283-1.662,0.668-3.773-0.72-5.04   c1.578-1.473,0.695-7.737,0.72-12.48c0.011-2.15,0.013-4.562-0.24-7.44c1.865-0.287,9.394,1.483,10.08-1.68   c0.278-1.282-0.411-2.678-1.92-2.88c0-0.08,0-0.16,0-0.24c-5.52,0-11.04,0-16.56,0c-1.044,0.556-3.117,0.083-4.56,0.24   c-0.548,0.972-1.546,1.494-1.44,3.12c2.226,2.553,6.886,0.52,10.32,1.44c-0.35,0.646,0.309,1.782-0.48,2.4   c0.747,19.645-0.255,39.448,0.72,58.56c-3.183,0.597-6.329-0.354-9.36,0c-2.355,7.279-4.178,16.254-6,23.279   c3.201,0.16,7.039-0.318,9.84,0.24c1.68,0,3.36,0,5.04,0c5.84-0.56,12.72-0.08,18.96-0.24c-1.603-7.917-3.857-15.183-5.52-23.04   C32.118,73.562,28.918,73.562,25.718,73.562z M30.518,93.483c-0.266-4.177,1.589-8.437-0.96-11.04   c0.037,0.586-1.649,0.693-2.4,1.199c0.17,2.439-1.679,2.749-0.24,4.561c-0.193-0.127-0.39-0.251-0.72-0.24   c0.922,0.479-0.333,1.728,0.96,1.92c-1.139,0.201-0.579,1.979-0.48,2.4c-1.895,0.639-2.856-0.222-4.56,0.96   c0.517-0.496,0.104-0.909-0.72-0.72c-0.847-3.143,1.717-9.625-2.16-9.841c-3.676-0.204-2.141,6.676-2.16,9.841   c-1.778,0.595-3.685,0.1-5.28,0.479c1.059-5.191,2.747-10.699,3.36-16.56c5.652,0.501,10.659-0.049,17.52,0.239   c1.422,5.538,3.218,10.703,4.08,16.801C34.678,93.483,32.598,93.483,30.518,93.483z"
        />
      </g>
    </svg>
  )
}
