export function BookNow(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" {...props}>
      <path
        fill="currentColor"
        d="M17.825 9L15 6.175l1.4-1.425l1.425 1.425l3.525-3.55l1.425 1.425L17.825 9ZM12 18l-7 3V3h8v2H7v12.95l5-2.15l5 2.15V11h2v10l-7-3ZM7 5h6h-6Z"
      ></path>
    </svg>
  )
}
