export const Other = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 72.58 100"
      enableBackground="new 0 0 72.58 100"
      xmlSpace="preserve"
      preserveAspectRatio="none"
      width="30px"
      height="30px"
      className=""
      fill="rgba(0, 102, 171, 1)"
      {...props}
    >
      <circle cx="36.29" cy="18.002" r="13.002" />
      <path d="M29.218,84.902h-9.9c0,0-3.3-1.485-1.65-6.237l7.404,0.046l4.102-9.296c-0.24-1.019-0.372-2.106-0.372-3.237h1.801  l5.984-13.562l-8.014-17.443c0,0-17.504,3.396-20.569,17.652L5.174,83.12c0,0-1.179,8.316,2.593,10.099  C11.539,95,29.689,95,29.689,95S36.525,89.061,29.218,84.902z" />
      <path d="M67.405,83.12l-2.829-30.295c-3.064-14.256-20.446-18.124-20.446-18.124L38,52.616l-5.75,13.562h11.527  c0,5.745-3.353,10.401-7.488,10.401c-2.687,0-5.042-1.967-6.363-4.919l-2.99,7.051l27.975-0.046c1.65,4.752-1.65,6.237-1.65,6.237  h-9.9C36.054,89.061,42.89,95,42.89,95s18.15,0,21.922-1.781C68.583,91.437,67.405,83.12,67.405,83.12z" />
    </svg>
  )
}
