import React from 'react'
import ReactDOM from 'react-dom/client'


import { ContextProvider } from "./context_global/Context";
import './index.scss'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
//import { DATA_ROOMS } from './mocks/rooms'
import RoomPage from './pages/room'

import { socket } from './socket_server/socket';
import { RegisterForm } from './configimg/RegisterForm'

let DATA_ROOMS = [];
let build_on_server = true;



socket.emit("allrouter", {}); // gửi cho máy chủ server nodejs để nhận danh sách phòng render


let root = ReactDOM.createRoot(document.getElementById('root'))

if (DATA_ROOMS.length == 0 && 0 == 0) { // render trước nếu như dữ liệu từ socket server chưa trả về
    var router = createBrowserRouter([
        {
            path: '/',
            element: <App />
        },
        {
            path: '/saveimg/',
            element: <RegisterForm />
        },
        ...DATA_ROOMS.map((room) => ({
            path: `/rooms/${room.villaPath}`,
            element: <RoomPage params={{ id: room.villaPath }} />
        }))
    ])
    /*
        ...DATA_ROOMS.map((room) => ({
          path: `/rooms/${room.villaPath}`,
          element: <RoomPage params={{ id: room.villaPath }} />
        }))
    
    */
    root.render(
        // <React.StrictMode>
        <ContextProvider>
            <RouterProvider router={router} />
        </ContextProvider >
        // </React.StrictMode>
    )
};



if (build_on_server == true) {//khoa_begin
    // dữ liệu từ server trả về
    // nhận được dánh sách phòng sau đó render ra
    socket.on('allrouter', msg => {

        console.log("Đã nhận được socket allrouter");

        //console.log(msg.allvilla) ;


        if (DATA_ROOMS.length == 0) {
            root = ReactDOM.createRoot(document.getElementById('root')); // đọc lại cái này để chúng cỏ thể bấm F5 ở các đường dẫn phụ
            //saveimg
            // bắt đầu render web khi nhận được danh sách
            DATA_ROOMS = msg.allrouter;
        } else {
            DATA_ROOMS = msg.allrouter;

        }


        console.log(DATA_ROOMS);
        var router = createBrowserRouter([
            {
                path: '/',
                element: <App />
            },
            {
                path: '/saveimg/',
                element: <RegisterForm />
            },
            ...DATA_ROOMS.map((room) => ({
                path: `/rooms/${room.villaPath}`,
                element: <RoomPage params={{ id: room.villaPath }} />
            }))
        ])
        /*
            ...DATA_ROOMS.map((room) => ({
              path: `/rooms/${room.villaPath}`,
              element: <RoomPage params={{ id: room.villaPath }} />
            }))
        
        */
        root.render(
            // <React.StrictMode>
            <ContextProvider>
                <RouterProvider router={router} />
            </ContextProvider >
            // </React.StrictMode>
        )


    });
};//khoa_end




if (build_on_server == false) {//build_trên_local_begin
    // demo DATA_ROOMS---> dùng để test begin

    DATA_ROOMS = [
        {
            "MaCan": "7.15",
            "price": "5400000",
            "hide": false,
            "code": "HAWAI VILLA 7.15",
            "villaName": "HAWAI VILLA",
            "villaPath": "hawai-villa",
            "rooms": "3",
            "wc": 4,
            "area": "300",
            "beds": "4",
            "people": [
                6,
                8
            ],
            "swimmingPool": false,
            "giftParty": false,
            "description": "HAWAI VILLA, với tên gọi thú vị, mang đến một không gian nghỉ dưỡng tuyệt vời và đầy phong cách, tạo điểm dừng chân lý tưởng cho những du khách mong muốn trải nghiệm sự thư giãn và hưởng thụ cuộc sống biển bên bờ đại dương.",
            "swim": "0",
            "images": {
                "length": 13,
                "path": "hawai-villa"
            },
            "villaAddress": "Novaworld Hồ Tràm, Đường Ven Biển@x@Bình Châu, Xuyên Mộc, Bà Rịa - Vũng Tàu",
            "adressshort": "VILLA BIỂN",
            "galleries": [
                {
                    "original": "_1.jpg",
                    "thumbnail": "_1.jpg"
                },
                {
                    "original": "_10.jpg",
                    "thumbnail": "_10.jpg"
                },
                {
                    "original": "_11.jpg",
                    "thumbnail": "_11.jpg"
                },
                {
                    "original": "_12.jpg",
                    "thumbnail": "_12.jpg"
                },
                {
                    "original": "_13.jpg",
                    "thumbnail": "_13.jpg"
                },
                {
                    "original": "_2.jpg",
                    "thumbnail": "_2.jpg"
                },
                {
                    "original": "_3.jpg",
                    "thumbnail": "_3.jpg"
                },
                {
                    "original": "_4.jpg",
                    "thumbnail": "_4.jpg"
                },
                {
                    "original": "_5.jpg",
                    "thumbnail": "_5.jpg"
                },
                {
                    "original": "_6.jpg",
                    "thumbnail": "_6.jpg"
                },
                {
                    "original": "_7.jpg",
                    "thumbnail": "_7.jpg"
                },
                {
                    "original": "_8.jpg",
                    "thumbnail": "_8.jpg"
                },
                {
                    "original": "_9.jpg",
                    "thumbnail": "_9.jpg"
                }
            ]
        },
        {
            "MaCan": "2.09",
            "price": "4500000",
            "hide": false,
            "code": "ESTELLA VILLA 2.09",
            "villaName": "ESTELLA VILLA",
            "villaPath": "estella-villa",
            "rooms": "2",
            "wc": 3,
            "area": "300",
            "beds": "4",
            "people": [
                4,
                6
            ],
            "swimmingPool": false,
            "giftParty": false,
            "description": "Estella Villa là một biểu tượng của sự sang trọng và tiện nghi, đặt trong khuôn viên tuyệt đẹp của một khu nghỉ dưỡng xa hoa. Villa này là một nơi lý tưởng để trốn khỏi cuộc sống đô thị ồn ào và tận hưởng không gian riêng tư và yên bình.",
            "swim": "0",
            "images": {
                "length": 26,
                "path": "estella-villa"
            },
            "villaAddress": "Novaworld Hồ Tràm, Đường Ven Biển@x@Bình Châu, Xuyên Mộc, Bà Rịa - Vũng Tàu",
            "adressshort": "VILLA BIỂN",
            "galleries": [
                {
                    "original": "_1.jpg",
                    "thumbnail": "_1.jpg"
                },
                {
                    "original": "_10.jpg",
                    "thumbnail": "_10.jpg"
                },
                {
                    "original": "_11.jpg",
                    "thumbnail": "_11.jpg"
                },
                {
                    "original": "_12.jpg",
                    "thumbnail": "_12.jpg"
                },
                {
                    "original": "_13.jpg",
                    "thumbnail": "_13.jpg"
                },
                {
                    "original": "_14.jpg",
                    "thumbnail": "_14.jpg"
                },
                {
                    "original": "_15.jpg",
                    "thumbnail": "_15.jpg"
                },
                {
                    "original": "_16.jpg",
                    "thumbnail": "_16.jpg"
                },
                {
                    "original": "_17.jpg",
                    "thumbnail": "_17.jpg"
                },
                {
                    "original": "_18.jpg",
                    "thumbnail": "_18.jpg"
                },
                {
                    "original": "_19.jpg",
                    "thumbnail": "_19.jpg"
                },
                {
                    "original": "_2.jpg",
                    "thumbnail": "_2.jpg"
                },
                {
                    "original": "_20.jpg",
                    "thumbnail": "_20.jpg"
                },
                {
                    "original": "_21.jpg",
                    "thumbnail": "_21.jpg"
                },
                {
                    "original": "_22.jpg",
                    "thumbnail": "_22.jpg"
                },
                {
                    "original": "_23.jpg",
                    "thumbnail": "_23.jpg"
                },
                {
                    "original": "_24.jpg",
                    "thumbnail": "_24.jpg"
                },
                {
                    "original": "_25.jpg",
                    "thumbnail": "_25.jpg"
                },
                {
                    "original": "_26.jpg",
                    "thumbnail": "_26.jpg"
                },
                {
                    "original": "_3.jpg",
                    "thumbnail": "_3.jpg"
                },
                {
                    "original": "_4.jpg",
                    "thumbnail": "_4.jpg"
                },
                {
                    "original": "_5.jpg",
                    "thumbnail": "_5.jpg"
                },
                {
                    "original": "_6.jpg",
                    "thumbnail": "_6.jpg"
                },
                {
                    "original": "_7.jpg",
                    "thumbnail": "_7.jpg"
                },
                {
                    "original": "_8.jpg",
                    "thumbnail": "_8.jpg"
                },
                {
                    "original": "_9.jpg",
                    "thumbnail": "_9.jpg"
                }
            ]
        },
        {
            "MaCan": "5.13",
            "price": "5000000",
            "hide": false,
            "code": "TOKYO VILLA 5.13",
            "villaName": "TOKYO VILLA",
            "villaPath": "tokyo-villa",
            "rooms": "3",
            "wc": 4,
            "area": "360",
            "beds": "5",
            "people": [
                6,
                8
            ],
            "swimmingPool": false,
            "giftParty": false,
            "description": "TOKYO VILLA chào đón bạn bằng không gian rộng rãi và sáng sủa. Thiết kế hiện đại và tinh tế mang lại cảm giác thoải mái và tinh thần mới mẻ. Với những cửa sổ lớn, ánh sáng tự nhiên tràn ngập căn phòng, mang đến không gian sống sáng và thoáng đãng.",
            "swim": "1",
            "images": {
                "length": 11,
                "path": "tokyo-villa"
            },
            "villaAddress": "Novaworld Hồ Tràm, Đường Ven Biển@x@Bình Châu, Xuyên Mộc, Bà Rịa - Vũng Tàu",
            "adressshort": "VILLA BIỂN",
            "galleries": [
                {
                    "original": "_1.jpg",
                    "thumbnail": "_1.jpg"
                },
                {
                    "original": "_10.jpg",
                    "thumbnail": "_10.jpg"
                },
                {
                    "original": "_11.jpg",
                    "thumbnail": "_11.jpg"
                },
                {
                    "original": "_2.jpg",
                    "thumbnail": "_2.jpg"
                },
                {
                    "original": "_3.jpg",
                    "thumbnail": "_3.jpg"
                },
                {
                    "original": "_4.jpg",
                    "thumbnail": "_4.jpg"
                },
                {
                    "original": "_5.jpg",
                    "thumbnail": "_5.jpg"
                },
                {
                    "original": "_6.jpg",
                    "thumbnail": "_6.jpg"
                },
                {
                    "original": "_7.jpg",
                    "thumbnail": "_7.jpg"
                },
                {
                    "original": "_8.jpg",
                    "thumbnail": "_8.jpg"
                },
                {
                    "original": "_9.jpg",
                    "thumbnail": "_9.jpg"
                }
            ]
        },
        {
            "MaCan": "2.08",
            "price": "12000000",
            "hide": false,
            "code": "AMSTERDAM VILLA 2.08",
            "villaName": "AMSTERDAM VILLA",
            "villaPath": "amsterdam-villa",
            "rooms": "3",
            "wc": 4,
            "area": "300",
            "beds": "4",
            "people": [
                4,
                6
            ],
            "swimmingPool": false,
            "giftParty": false,
            "description": "AMSTERDAM VILLA Thiết kế màu sắc hài hòa đẹp với những bình hoa đẹp rực rỡ và các khu vực tiếp khách thoải mái, tạo điều kiện lý tưởng cho những cuộc gặp gỡ gia đình và bạn bè. Với nội thất sang trọng và các tiện ích tiện nghi, bạn có thể tổ chức những buổi tiệc nhỏ, dạo chơi cùng nhau hoặc thư giãn trong không gian sống chung đẳng cấp.",
            "swim": "0",
            "images": {
                "length": 23,
                "path": "amsterdam-villa"
            },
            "villaAddress": "Novaworld Hồ Tràm, Đường Ven Biển@x@Bình Châu, Xuyên Mộc, Bà Rịa - Vũng Tàu",
            "adressshort": "VILLA BIỂN",
            "galleries": [
                {
                    "original": "_1.jpg",
                    "thumbnail": "_1.jpg"
                },
                {
                    "original": "_10.jpg",
                    "thumbnail": "_10.jpg"
                },
                {
                    "original": "_11.jpg",
                    "thumbnail": "_11.jpg"
                },
                {
                    "original": "_12.jpg",
                    "thumbnail": "_12.jpg"
                },
                {
                    "original": "_13.jpg",
                    "thumbnail": "_13.jpg"
                },
                {
                    "original": "_14.jpg",
                    "thumbnail": "_14.jpg"
                },
                {
                    "original": "_15.jpg",
                    "thumbnail": "_15.jpg"
                },
                {
                    "original": "_16.jpg",
                    "thumbnail": "_16.jpg"
                },
                {
                    "original": "_17.jpg",
                    "thumbnail": "_17.jpg"
                },
                {
                    "original": "_18.jpg",
                    "thumbnail": "_18.jpg"
                },
                {
                    "original": "_19.jpg",
                    "thumbnail": "_19.jpg"
                },
                {
                    "original": "_2.jpg",
                    "thumbnail": "_2.jpg"
                },
                {
                    "original": "_20.jpg",
                    "thumbnail": "_20.jpg"
                },
                {
                    "original": "_21.jpg",
                    "thumbnail": "_21.jpg"
                },
                {
                    "original": "_22.jpg",
                    "thumbnail": "_22.jpg"
                },
                {
                    "original": "_23.jpg",
                    "thumbnail": "_23.jpg"
                },
                {
                    "original": "_3.jpg",
                    "thumbnail": "_3.jpg"
                },
                {
                    "original": "_4.jpg",
                    "thumbnail": "_4.jpg"
                },
                {
                    "original": "_5.jpg",
                    "thumbnail": "_5.jpg"
                },
                {
                    "original": "_6.jpg",
                    "thumbnail": "_6.jpg"
                },
                {
                    "original": "_7.jpg",
                    "thumbnail": "_7.jpg"
                },
                {
                    "original": "_8.jpg",
                    "thumbnail": "_8.jpg"
                },
                {
                    "original": "_9.jpg",
                    "thumbnail": "_9.jpg"
                }
            ]
        },
        {
            "MaCan": "1.34",
            "price": "4800000",
            "hide": false,
            "code": "JUPITER VILLA 1.34",
            "villaName": "JUPITER VILLA",
            "villaPath": "jupiter-villa",
            "rooms": "2",
            "wc": 3,
            "area": "200",
            "beds": "4",
            "people": [
                4,
                6
            ],
            "swimmingPool": false,
            "giftParty": false,
            "description": "JUPITER VILLA là một biểu tượng của sự sang trọng và tiện nghi, đặt trong khuôn viên tuyệt đẹp của một khu nghỉ dưỡng xa hoa. Villa này là một nơi lý tưởng để trốn khỏi cuộc sống đô thị ồn ào và tận hưởng không gian riêng tư và yên bình.",
            "swim": "1",
            "images": {
                "length": 42,
                "path": "jupiter-villa"
            },
            "villaAddress": "Novaworld Hồ Tràm, Đường Ven Biển@x@Bình Châu, Xuyên Mộc, Bà Rịa - Vũng Tàu",
            "adressshort": "VILLA ĐỒI",
            "galleries": [
                {
                    "original": "IMG_6392.jpg",
                    "thumbnail": "IMG_6392.jpg"
                },
                {
                    "original": "IMG_6393.jpg",
                    "thumbnail": "IMG_6393.jpg"
                },
                {
                    "original": "IMG_6394.jpg",
                    "thumbnail": "IMG_6394.jpg"
                },
                {
                    "original": "IMG_6395.jpg",
                    "thumbnail": "IMG_6395.jpg"
                },
                {
                    "original": "IMG_6397.jpg",
                    "thumbnail": "IMG_6397.jpg"
                },
                {
                    "original": "IMG_6398.jpg",
                    "thumbnail": "IMG_6398.jpg"
                },
                {
                    "original": "IMG_6399.jpg",
                    "thumbnail": "IMG_6399.jpg"
                },
                {
                    "original": "IMG_6400.jpg",
                    "thumbnail": "IMG_6400.jpg"
                },
                {
                    "original": "IMG_6401.jpg",
                    "thumbnail": "IMG_6401.jpg"
                },
                {
                    "original": "IMG_6402.jpg",
                    "thumbnail": "IMG_6402.jpg"
                },
                {
                    "original": "IMG_6403.jpg",
                    "thumbnail": "IMG_6403.jpg"
                },
                {
                    "original": "IMG_6404.jpg",
                    "thumbnail": "IMG_6404.jpg"
                },
                {
                    "original": "IMG_6405.jpg",
                    "thumbnail": "IMG_6405.jpg"
                },
                {
                    "original": "IMG_6407.jpg",
                    "thumbnail": "IMG_6407.jpg"
                },
                {
                    "original": "IMG_6408.jpg",
                    "thumbnail": "IMG_6408.jpg"
                },
                {
                    "original": "IMG_6409.jpg",
                    "thumbnail": "IMG_6409.jpg"
                },
                {
                    "original": "IMG_6412.jpg",
                    "thumbnail": "IMG_6412.jpg"
                },
                {
                    "original": "IMG_6415.jpg",
                    "thumbnail": "IMG_6415.jpg"
                },
                {
                    "original": "IMG_6417.jpg",
                    "thumbnail": "IMG_6417.jpg"
                },
                {
                    "original": "IMG_6418.jpg",
                    "thumbnail": "IMG_6418.jpg"
                },
                {
                    "original": "IMG_6419.jpg",
                    "thumbnail": "IMG_6419.jpg"
                },
                {
                    "original": "IMG_6420.jpg",
                    "thumbnail": "IMG_6420.jpg"
                },
                {
                    "original": "IMG_6421.jpg",
                    "thumbnail": "IMG_6421.jpg"
                },
                {
                    "original": "IMG_6423.jpg",
                    "thumbnail": "IMG_6423.jpg"
                },
                {
                    "original": "IMG_6424.jpg",
                    "thumbnail": "IMG_6424.jpg"
                },
                {
                    "original": "IMG_6425.jpg",
                    "thumbnail": "IMG_6425.jpg"
                },
                {
                    "original": "IMG_6426.jpg",
                    "thumbnail": "IMG_6426.jpg"
                },
                {
                    "original": "IMG_6427.jpg",
                    "thumbnail": "IMG_6427.jpg"
                },
                {
                    "original": "IMG_6428.jpg",
                    "thumbnail": "IMG_6428.jpg"
                },
                {
                    "original": "IMG_6430.jpg",
                    "thumbnail": "IMG_6430.jpg"
                },
                {
                    "original": "IMG_6432.jpg",
                    "thumbnail": "IMG_6432.jpg"
                },
                {
                    "original": "IMG_6433.jpg",
                    "thumbnail": "IMG_6433.jpg"
                },
                {
                    "original": "IMG_6435.jpg",
                    "thumbnail": "IMG_6435.jpg"
                },
                {
                    "original": "IMG_6436.jpg",
                    "thumbnail": "IMG_6436.jpg"
                },
                {
                    "original": "IMG_6437.jpg",
                    "thumbnail": "IMG_6437.jpg"
                },
                {
                    "original": "IMG_6439.jpg",
                    "thumbnail": "IMG_6439.jpg"
                },
                {
                    "original": "IMG_6440.jpg",
                    "thumbnail": "IMG_6440.jpg"
                },
                {
                    "original": "IMG_6441.jpg",
                    "thumbnail": "IMG_6441.jpg"
                },
                {
                    "original": "IMG_6443.jpg",
                    "thumbnail": "IMG_6443.jpg"
                },
                {
                    "original": "IMG_6388.jpg",
                    "thumbnail": "IMG_6388.jpg"
                },
                {
                    "original": "IMG_6389.jpg",
                    "thumbnail": "IMG_6389.jpg"
                },
                {
                    "original": "IMG_6391.jpg",
                    "thumbnail": "IMG_6391.jpg"
                }
            ]
        },
        {
            "MaCan": "2.05",
            "price": "5000000",
            "hide": false,
            "code": "HABANA VILLA 2.05",
            "villaName": "HABANA VILLA",
            "villaPath": "habana-villa",
            "rooms": "2",
            "wc": 3,
            "area": "300",
            "beds": "5",
            "people": [
                4,
                6
            ],
            "swimmingPool": false,
            "giftParty": false,
            "description": "HABANA VILLA là một biểu tượng của sự sang trọng và tiện ",
            "swim": "0",
            "images": {
                "length": 23,
                "path": "habana-villa"
            },
            "villaAddress": "Novaworld Hồ Tràm, Đường Ven Biển@x@Bình Châu, Xuyên Mộc, Bà Rịa - Vũng Tàu",
            "adressshort": "VILLA BIỂN",
            "galleries": [
                {
                    "original": "IMG_7541.jpg",
                    "thumbnail": "IMG_7541.jpg"
                },
                {
                    "original": "IMG_7544.jpg",
                    "thumbnail": "IMG_7544.jpg"
                },
                {
                    "original": "IMG_7546.jpg",
                    "thumbnail": "IMG_7546.jpg"
                },
                {
                    "original": "IMG_7547.jpg",
                    "thumbnail": "IMG_7547.jpg"
                },
                {
                    "original": "IMG_7548.jpg",
                    "thumbnail": "IMG_7548.jpg"
                },
                {
                    "original": "IMG_7550.jpg",
                    "thumbnail": "IMG_7550.jpg"
                },
                {
                    "original": "IMG_7551.jpg",
                    "thumbnail": "IMG_7551.jpg"
                },
                {
                    "original": "IMG_7554.jpg",
                    "thumbnail": "IMG_7554.jpg"
                },
                {
                    "original": "IMG_7555.jpg",
                    "thumbnail": "IMG_7555.jpg"
                },
                {
                    "original": "IMG_7556.jpg",
                    "thumbnail": "IMG_7556.jpg"
                },
                {
                    "original": "IMG_7557.jpg",
                    "thumbnail": "IMG_7557.jpg"
                },
                {
                    "original": "IMG_7558.jpg",
                    "thumbnail": "IMG_7558.jpg"
                },
                {
                    "original": "IMG_7559.jpg",
                    "thumbnail": "IMG_7559.jpg"
                },
                {
                    "original": "IMG_7560.jpg",
                    "thumbnail": "IMG_7560.jpg"
                },
                {
                    "original": "IMG_7561.jpg",
                    "thumbnail": "IMG_7561.jpg"
                },
                {
                    "original": "IMG_7562.jpg",
                    "thumbnail": "IMG_7562.jpg"
                },
                {
                    "original": "IMG_7563.jpg",
                    "thumbnail": "IMG_7563.jpg"
                },
                {
                    "original": "IMG_7564.jpg",
                    "thumbnail": "IMG_7564.jpg"
                },
                {
                    "original": "IMG_7718.jpg",
                    "thumbnail": "IMG_7718.jpg"
                },
                {
                    "original": "1.jpg",
                    "thumbnail": "1.jpg"
                },
                {
                    "original": "IMG_7538.jpg",
                    "thumbnail": "IMG_7538.jpg"
                },
                {
                    "original": "IMG_7539.jpg",
                    "thumbnail": "IMG_7539.jpg"
                },
                {
                    "original": "IMG_7540.jpg",
                    "thumbnail": "IMG_7540.jpg"
                }
            ]
        },
        {
            "MaCan": "6.46",
            "price": "4800000",
            "hide": false,
            "code": "DUBAI VILLA 6.46",
            "villaName": "DUBAI VILLA",
            "villaPath": "dubai-villa",
            "rooms": "3",
            "wc": 3,
            "area": "160",
            "beds": "3",
            "people": [
                4,
                6
            ],
            "swimmingPool": false,
            "giftParty": false,
            "description": "DUBAI VILLA, với tên gọi đầy mê hoặc, đưa bạn vào một cuộc phiêu lưu xa hoa và đẳng cấp trong thành phố kỳ diệu của Dubai. Với kiến trúc hiện đại và không gian sang trọng, căn villa này mang đến một trải nghiệm đắm chìm trong xa hoa và sự phấn khích của một trong những thành phố phát triển nhanh nhất thế giới.",
            "swim": "0",
            "images": {
                "length": 20,
                "path": "dubai-villa"
            },
            "villaAddress": "Novaworld Hồ Tràm, Đường Ven Biển@x@Bình Châu, Xuyên Mộc, Bà Rịa - Vũng Tàu",
            "adressshort": "VILLA ĐỒI",
            "galleries": [
                {
                    "original": "_1.jpg",
                    "thumbnail": "_1.jpg"
                },
                {
                    "original": "_10.jpg",
                    "thumbnail": "_10.jpg"
                },
                {
                    "original": "_11.jpg",
                    "thumbnail": "_11.jpg"
                },
                {
                    "original": "_12.jpg",
                    "thumbnail": "_12.jpg"
                },
                {
                    "original": "_13.jpg",
                    "thumbnail": "_13.jpg"
                },
                {
                    "original": "_14.jpg",
                    "thumbnail": "_14.jpg"
                },
                {
                    "original": "_15.jpg",
                    "thumbnail": "_15.jpg"
                },
                {
                    "original": "_16.jpg",
                    "thumbnail": "_16.jpg"
                },
                {
                    "original": "_17.jpg",
                    "thumbnail": "_17.jpg"
                },
                {
                    "original": "_18.jpg",
                    "thumbnail": "_18.jpg"
                },
                {
                    "original": "_19.jpg",
                    "thumbnail": "_19.jpg"
                },
                {
                    "original": "_2.jpg",
                    "thumbnail": "_2.jpg"
                },
                {
                    "original": "_20.jpg",
                    "thumbnail": "_20.jpg"
                },
                {
                    "original": "_3.jpg",
                    "thumbnail": "_3.jpg"
                },
                {
                    "original": "_4.jpg",
                    "thumbnail": "_4.jpg"
                },
                {
                    "original": "_5.jpg",
                    "thumbnail": "_5.jpg"
                },
                {
                    "original": "_6.jpg",
                    "thumbnail": "_6.jpg"
                },
                {
                    "original": "_7.jpg",
                    "thumbnail": "_7.jpg"
                },
                {
                    "original": "_8.jpg",
                    "thumbnail": "_8.jpg"
                },
                {
                    "original": "_9.jpg",
                    "thumbnail": "_9.jpg"
                }
            ]
        },
        {
            "MaCan": "1.08",
            "price": "10000000",
            "hide": false,
            "code": "JOLENE VILLA 1.08",
            "villaName": "JOLENE VILLA",
            "villaPath": "jolene-villa",
            "rooms": "4",
            "wc": 4,
            "area": "500",
            "beds": "6",
            "people": [
                8,
                10
            ],
            "swimmingPool": false,
            "giftParty": false,
            "description": "JOLENE VILLA Party mang đến không gian sống vui nhộn và phong cách. Căn villa được thiết kế để đáp ứng nhu cầu của những buổi tiệc lớn hoặc sự kiện đặc biệt. Phòng khách rộng rãi và khu vực tiếp khách được bố trí một cách thông thoáng và tinh tế, tạo điều kiện lý tưởng cho việc tổ chức các buổi tiệc hoành tráng và những cuộc họp mặt thú vị.",
            "swim": "1",
            "images": {
                "length": 12,
                "path": "jolene-villa"
            },
            "villaAddress": "Novaworld Hồ Tràm, Đường Ven Biển@x@Bình Châu, Xuyên Mộc, Bà Rịa - Vũng Tàu",
            "adressshort": "VILLA SÁT BIỂN",
            "galleries": [
                {
                    "original": "_1.jpg",
                    "thumbnail": "_1.jpg"
                },
                {
                    "original": "_10.jpg",
                    "thumbnail": "_10.jpg"
                },
                {
                    "original": "_11.jpg",
                    "thumbnail": "_11.jpg"
                },
                {
                    "original": "_12.jpg",
                    "thumbnail": "_12.jpg"
                },
                {
                    "original": "_2.jpg",
                    "thumbnail": "_2.jpg"
                },
                {
                    "original": "_3.jpg",
                    "thumbnail": "_3.jpg"
                },
                {
                    "original": "_4.jpg",
                    "thumbnail": "_4.jpg"
                },
                {
                    "original": "_5.jpg",
                    "thumbnail": "_5.jpg"
                },
                {
                    "original": "_6.jpg",
                    "thumbnail": "_6.jpg"
                },
                {
                    "original": "_7.jpg",
                    "thumbnail": "_7.jpg"
                },
                {
                    "original": "_8.jpg",
                    "thumbnail": "_8.jpg"
                },
                {
                    "original": "_9.jpg",
                    "thumbnail": "_9.jpg"
                }
            ]
        },
        {
            "MaCan": "7.03",
            "price": "4800000",
            "hide": false,
            "code": "AURORA VILLA 7.03",
            "villaName": "AURORA VILLA",
            "villaPath": "aurora-villa",
            "rooms": "2",
            "wc": 3,
            "area": "240",
            "beds": "3",
            "people": [
                2,
                4
            ],
            "swimmingPool": false,
            "giftParty": false,
            "description": "AURORA VILLA là một biểu tượng của sự sang trọng và tiện nghi, đặt trong khuôn viên tuyệt đẹp của một khu nghỉ dưỡng xa hoa. Villa này là một nơi lý tưởng để trốn khỏi cuộc sống đô thị ồn ào và tận hưởng không gian riêng tư và yên bình.",
            "swim": "0",
            "images": {
                "length": 20,
                "path": "aurora-villa"
            },
            "villaAddress": "Novaworld Hồ Tràm, Đường Ven Biển@x@Bình Châu, Xuyên Mộc, Bà Rịa - Vũng Tàu",
            "adressshort": "VILLA BIỂN",
            "galleries": [
                {
                    "original": "_1.jpg",
                    "thumbnail": "_1.jpg"
                },
                {
                    "original": "_10.jpg",
                    "thumbnail": "_10.jpg"
                },
                {
                    "original": "_11.jpg",
                    "thumbnail": "_11.jpg"
                },
                {
                    "original": "_12.jpg",
                    "thumbnail": "_12.jpg"
                },
                {
                    "original": "_13.jpg",
                    "thumbnail": "_13.jpg"
                },
                {
                    "original": "_14.jpg",
                    "thumbnail": "_14.jpg"
                },
                {
                    "original": "_15.jpg",
                    "thumbnail": "_15.jpg"
                },
                {
                    "original": "_16.jpg",
                    "thumbnail": "_16.jpg"
                },
                {
                    "original": "_17.jpg",
                    "thumbnail": "_17.jpg"
                },
                {
                    "original": "_18.jpg",
                    "thumbnail": "_18.jpg"
                },
                {
                    "original": "_19.jpg",
                    "thumbnail": "_19.jpg"
                },
                {
                    "original": "_2.jpg",
                    "thumbnail": "_2.jpg"
                },
                {
                    "original": "_20.jpg",
                    "thumbnail": "_20.jpg"
                },
                {
                    "original": "_3.jpg",
                    "thumbnail": "_3.jpg"
                },
                {
                    "original": "_4.jpg",
                    "thumbnail": "_4.jpg"
                },
                {
                    "original": "_5.jpg",
                    "thumbnail": "_5.jpg"
                },
                {
                    "original": "_6.jpg",
                    "thumbnail": "_6.jpg"
                },
                {
                    "original": "_7.jpg",
                    "thumbnail": "_7.jpg"
                },
                {
                    "original": "_8.jpg",
                    "thumbnail": "_8.jpg"
                },
                {
                    "original": "_9.jpg",
                    "thumbnail": "_9.jpg"
                }
            ]
        },
        {
            "MaCan": "6.47",
            "price": "3500000",
            "hide": false,
            "code": "NEW YORK VILLA 6.47",
            "villaName": "NEW YORK VILLA",
            "villaPath": "new-york-villa",
            "rooms": "3",
            "wc": 3,
            "area": "160",
            "beds": "3",
            "people": [
                4,
                6
            ],
            "swimmingPool": false,
            "giftParty": false,
            "description": "Estella Villa là một biểu tượng của sự sang trọng và tiện nghi, đặt trong khuôn viên tuyệt đẹp của một khu nghỉ dưỡng xa hoa. Villa này là một nơi lý tưởng để trốn khỏi cuộc sống đô thị ồn ào và tận hưởng không gian riêng tư và yên bình.",
            "swim": "0",
            "images": {
                "length": 13,
                "path": "new-york-villa"
            },
            "villaAddress": "Novaworld Hồ Tràm, Đường Ven Biển@x@Bình Châu, Xuyên Mộc, Bà Rịa - Vũng Tàu",
            "adressshort": "VILLA ĐỒI",
            "galleries": [
                {
                    "original": "_1.jpg",
                    "thumbnail": "_1.jpg"
                },
                {
                    "original": "_10.jpg",
                    "thumbnail": "_10.jpg"
                },
                {
                    "original": "_11.jpg",
                    "thumbnail": "_11.jpg"
                },
                {
                    "original": "_12.jpg",
                    "thumbnail": "_12.jpg"
                },
                {
                    "original": "_13.jpg",
                    "thumbnail": "_13.jpg"
                },
                {
                    "original": "_2.jpg",
                    "thumbnail": "_2.jpg"
                },
                {
                    "original": "_3.jpg",
                    "thumbnail": "_3.jpg"
                },
                {
                    "original": "_4.jpg",
                    "thumbnail": "_4.jpg"
                },
                {
                    "original": "_5.jpg",
                    "thumbnail": "_5.jpg"
                },
                {
                    "original": "_6.jpg",
                    "thumbnail": "_6.jpg"
                },
                {
                    "original": "_7.jpg",
                    "thumbnail": "_7.jpg"
                },
                {
                    "original": "_8.jpg",
                    "thumbnail": "_8.jpg"
                },
                {
                    "original": "_9.jpg",
                    "thumbnail": "_9.jpg"
                }
            ]
        },
        {
            "MaCan": "9.1",
            "hide": false,
            "code": "VILLA 209 CẦN BÁN 9.1",
            "villaName": "VILLA 209 CẦN BÁN",
            "villaPath": "villa-209-cần-bán",
            "rooms": "CĂN VILLA BIỂN 300M2",
            "wc": null,
            "area": "Diện Tích Đất Là : 15x20",
            "beds": "Hiện trạng: Hoàn thiện nội thất nhà mẫu Novaland",
            "people": [
                -2,
                0
            ],
            "swimmingPool": false,
            "giftParty": false,
            "description": "11 tỷ (Đã bao gồm VAT)@T@10.4 Tỷ (Tương đương 95%)@T@Biệt Thự đơn lập@T@12x20m,240m2@T@150m2@T@Hoàn thiện bên ngoài, thô bên trong@T@nhận ngay@T@Đông Bắc@T@Hợp đồng mua bán@T@Bên bán không vay@T@Tối đa 70% giá trị HĐMB@T@0967 953 754 - Văng Thành Ngây",
            "swim": "Giá bán - số tiền cần thanh toán : 10.4 tỷ",
            "images": {
                "length": 0,
                "path": "villa-209-cần-bán"
            },
            "villaAddress": "",
            "adressshort": "",
            "galleries": []
        },
        {
            "MaCan": "2.1",
            "hide": false,
            "code": "SHOPHOUSE CẦN BÁN 2.1",
            "villaName": "SHOPHOUSE CẦN BÁN",
            "villaPath": "shophouse-cần-bán",
            "rooms": "CĂN SHOPHOUSE 180M2",
            "wc": null,
            "area": "Diện Tích Đất Là : 6x30m",
            "beds": "Hiện trạng: Hoàn thiện nội thất để kinh doanh",
            "people": [
                -2,
                0
            ],
            "swimmingPool": false,
            "giftParty": false,
            "description": "8 tỷ (Đã bao gồm VAT)@T@8 Tỷ (Tương đương 95%)@T@Nhà phố thương mại@T@6x30m,180m2@T@168m2@T@Đã hoàn thiện nội thất kinh doanh@T@nhận ngay@T@Đông Nam@T@Hợp đồng mua bán@T@Bên bán đang vay ngân hàng@T@Tối đa 70% giá trị HĐMB@T@0967 953 754 - Văng Thành Ngây",
            "swim": "Giá bán - chủ nhà nhận về : 8 tỷ (giá đã mua là 17 tỷ)",
            "images": {
                "length": 1,
                "path": "shophouse-cần-bán"
            },
            "villaAddress": "",
            "adressshort": "",
            "galleries": [
                {
                    "original": "f00c356e-35a7-441a-bb29-73ad8515625f1.png",
                    "thumbnail": "f00c356e-35a7-441a-bb29-73ad8515625f1.png"
                }
            ]
        }
    ]
    console.log(DATA_ROOMS);
    const router = createBrowserRouter([
        {
            path: '/',
            element: <App />
        },
        ...DATA_ROOMS.map((room) => ({
            path: `/rooms/${room.villaPath}`,
            element: <RoomPage params={{ id: room.villaPath }} />
        }))
    ])

    root.render(
        // A
        <ContextProvider>
            <RouterProvider router={router} />
        </ContextProvider >
        // </React.StrictMode>
    )
};//build_trên_local_end
// demo DATA_ROOMS---> dùng để test end



reportWebVitals()