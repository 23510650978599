// component này trình chiếu danh sách sản phẩm trên trang chính

// import những chức năng của react
import React from 'react'




// khu vực import những react-icon mô tả cho ngôn từ đã nói
import { Area } from '../../../icons/Area'
import { Bed } from '../../../icons/Bed'
import { HotelRoom } from '../../../icons/HotelRoom'
import { LocationMap } from '../../../icons/LocationMap'
import { Villaprice } from '../../../icons/Villaprice'

// import định dạng cách khoản bởi dấu chấm tiền bạc
import {formatCurrency}  from '../../../function/utilities'



/* 
 item = {
    price: "120000",
    hide: false,
    code: 'ESTELLA VILLA 2.09',
    villaName: 'ESTELLA VILLA',
    villaPath: 'estella-villa',
    rooms: 2,
    wc: 3,
    area: 300,
    beds: 4,
    people: [4, 6],
    swimmingPool: false,
    giftParty: false,
    description:
      'Estella Villa là một biểu tượng của sự sang trọng và tiện nghi, đặt trong khuôn viên tuyệt đẹp của một khu nghỉ dưỡng xa hoa. Villa này là một nơi lý tưởng để trốn khỏi cuộc sống đô thị ồn ào và tận hưởng không gian riêng tư và yên bình.',
    images: {
      length: 26,
      path: 'estella-villa'
    }


*/


//C:\Users\pchai\OneDrive\MaychuNodejs\VPS_web\server_nodejs_web\nghiencua_socket_choweb_nova\thu_nghiem_serersocket_nodejs_webstie\cilent_nova_socket\src\components\Nova\BookRoom\RoomInfo.jsx
export const RoomInfo = ({ item }) => {


  // xử lý địa chỉ nhận được và render nó lên web begin
  let chuoidau = item.villaAddress;

  let tachdau = chuoidau.split("@x@");
  let diachitren = tachdau[0];
  let diachiduoi = tachdau[1];

  let outdress =  (
  <>
    {diachitren},
    <br /> {diachiduoi}
  </>
);
// xử lý địa chỉ nhận được và render nó lên web end


  return (
    <div className="flex flex-col ml-2 md:ml-4 gap-1 w-auto">
      <h3 className="font-bold text-md md:text-xl ">
        {item.villaName}{' '}
        {item.villaSubName ? (
          <span className="text-sm md:text-md font-light">{item.villaSubName}</span>
        ) : null}
      </h3>
      <p className="text-sm md:text-md flex gap-1 md:gap-2 items-center">
        {/* <Area className="inline-block text-lg w-5 h-auto" /> */}
        {/* CĂN VILLA 240M2 */}
        {item.rooms}
      </p>
      <p className="text-sm md:text-md flex gap-1 md:gap-2 items-center">
        {/* <HotelRoom className="inline-block text-lg w-5 h-auto" /> */}
        
        {item.area}m<sup>2</sup>
      </p>
      <p className="text-sm md:text-md flex gap-1 md:gap-2 items-center">
        {/* <Bed className="inline-block text-lg w-5 h-auto" /> */}
        {item.beds}
      </p>

      <div className="text-sm md:text-md flex gap-1 md:gap-2 items-center">
       {/*  <p className="inline-block text-lg w-5 h-auto">Giá:</p> <span> </span> */}
        <p className="font-bold text-md md:text-xl col-span-4">
          {/* <Villaprice className="inline-block text-lg w-5 h-auto mr-1" /> */}
          <span className='text-red-500'>
            {/* {formatCurrency(item.price)} VND */}
            {item.swim}
          </span>

        </p>
      </div>


{/* hidden md:text-md text-sm*/}
      {/* <a
        className="md:flex gap-1 md:gap-2 items-center hover:text-blue-600 inforadress"
        href="https://www.google.com/maps?q=HHG3+588+Happy+Stay+(Hotel),+%E1%BA%A4p+khu+1,+Xuy%C3%AAn+M%E1%BB%99c,+B%C3%A0+R%E1%BB%8Ba+-+V%C5%A9ng+T%C3%A0u+78551&ftid=0x3175bb5b4e48573f:0xe4ae993ddce2ac67&hl=vi-VN&gl=vn&coh=176814&entry=gps&lucs=,47065934&g_ep=CAISBjYuNzguMxgAINeCAyoJLDQ3MDY1OTM0QgJWTg%3D%3D&g_st=iz"
        target="_blank"
        rel="noreferrer"
      >
        <LocationMap className="inline-block text-lg w-6 h-auto" />
        {outdress}
      </a> */}


    </div>
  )
}
