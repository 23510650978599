// Nơi hiển thị chi tiết các villa  : \src\pages\room\index.jsx
/* 
+ Nơi thanh menu chạy theo web ở địa chỉ :
src\components\Nova\TopHeader\index.jsx

+ Phần đầu của web :
src\components\Nova\Header\index.jsx

+Nơi hiển thị room chi tiết trên trang chủ  :
\src\components\Nova\BookRoom\RoomInfo.jsx

+nơi hiển thị từng dòng sản phẩm riêng biệt khi nhấp vào : chi tiết :
src\pages\room\index.jsx

+ Khi set SQL cột data thì nạp dữ liệu vào phần server nodejs ở vị trí sau:
server_web_test\function_nodejs\villaAPI\Callallvilla.js

+ Nơi đặt booking của website :  src\Bookroomalldate\index.jsx

+Nơi các chi tiết của villa được input vào (đã bỏ) :
src\mocks\rooms.jsx


*/
import React from 'react'
import './App.scss'
import { Layout } from './Layout'
import { NovaHeader } from './components/Nova/Header'
import { NovaIntroVideo } from './components/Nova/IntroVideo'
import { NovaMemories } from './components/Nova/Memories'
import { Nhahang } from './components/Nova/Restaurant'

import { Baotag } from './components/Nova/Baotang'

import { NovaBookRoom } from './components/Nova/BookRoom'
//import { NovaRegisterForm } from './components/Nova/RegisterFormContainer'
import { NovaServices } from './components/Nova/Services'
import { NovaPosterGift } from './components/Nova/PosterGift'
import { NovaInformation } from './components/Nova/Information'

import { NovaBookhotel } from './components/Nova/Bookhotel'

import { useContext } from "react";
import { Context } from "./context_global/Context";

import ImageView from './comview/ImageView'; 

import Banner from './Banner/Banner';

//socket begin
import { useState, useEffect } from 'react';
import { socket } from './socket_server/socket';
// để in ra trạng thái có kết nối socket máy chủ hay chưa.
import { ConnectionState } from './socket_server/ConnectionState';
// tạo một form input dữ liệu nhập tay từ người dùng gửi theo một bức meail + key
import { Events } from './socket_server/MyEvents';
// tạo ra nút bấm để điều khiển kết nối và ngắt kết nối
import { ConnectionManager } from './socket_server/ConnectionManager';
// tạo ra một nơi để gửi bức thư về máy chủ
import { MyForm } from './socket_server/MyForm';
//socket end
import Baibao from './Baibao/Baibao'; 



function App() {
  const { timewaitVILLA, settimewaitVILLA, finshHeard } = useContext(Context);

  //PosterGift
  const [showBookRoom, setShowBookRoom] = useState(false);
  const [showIntroVideo, setshowIntroVideo] = useState(false);

  const [showMemories, setshowMemories] = useState(false);

  const [showRegisterForm, setshowRegisterForm] = useState(false);

  const [showServices, setshowServices] = useState(false);

  const [showInformation, setshowInformation] = useState(false);

  const [showPosterGift, setshowPosterGift] = useState(false);
  const [tenanhgia, settenanhgia] = useState({key : false, namegia : ""});

  

  const [isConnected, setIsConnected] = useState(socket.connected); // thể hiện rằng nó có kết nối hoặc không
  const [fooEvents, setFooEvents] = useState([]);
  useEffect(() => {
    function onConnect() { // khi mà máy chủ đã kết nối thì : isConnected = true
      setIsConnected(true);
    }
    function onDisconnect() { // khi máy chủ ngắt kết nối thì : isConnected = false
      setIsConnected(false);
    }

    function onFooEvent(value) { // có một sự kiện nào đó mang tên 'foo'
      setFooEvents(previous => [...previous, value]);
    }

    socket.on('connect', onConnect); // sự kiện đã kết nối.
    socket.on('disconnect', onDisconnect); // sự kiện đã ngắt kết nối


    socket.on('foo', msg => { // có tin nhắn tới với key = 'chat message'
      alert(msg.data)
      onFooEvent(msg.data)

    });

    socket.on('chat message', msg => { // có tin nhắn tới với key = 'chat message'
      alert(msg.data)
      console.log("msg:", msg.data);

    });

    //gọi qua máy chủ lấy tên ảnh giá
    socket.emit("tenanhgia", 1);
    socket.on('tenanhgia', msg => { 
      //alert(msg.data)
      //console.log("msg:", msg.data);
      // data : 
      settenanhgia({key : true, namegia : msg.tenanhgia});

    });

    return () => { // trả về sự kiện để có thể làm sạch lịch sử của nó
      socket.off('connect', onConnect);
      socket.off('disconnect', onDisconnect);
      socket.off('foo', onFooEvent);
    };


  }, []);



  useEffect(() => {
    const timeout = setTimeout(() => {
      settimewaitVILLA(true);
      setShowBookRoom(true);
    }, 3000); // Thời gian trễ, ở đây là 2000ms (2 giây)

    return () => {
      clearTimeout(timeout);
    };
  }, []);



  useEffect(() => {
    const timeout = setTimeout(() => {
      setshowIntroVideo(true);
    }, 500); // Thời gian trễ, ở đây là 2000ms (2 giây)

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  //showIntroVideo

  useEffect(() => {
    const timeout = setTimeout(() => {
      setshowMemories(true);
    }, 2000); // Thời gian trễ, ở đây là 2000ms (2 giây)

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setshowRegisterForm(true);
    }, 4000); // Thời gian trễ, ở đây là 2000ms (2 giây)

    return () => {
      clearTimeout(timeout);
    };
  }, []);


  useEffect(() => {
    const timeout = setTimeout(() => {
      setshowServices(true);
    }, 8000); // Thời gian trễ, ở đây là 2000ms (2 giây)

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setshowInformation(true);
    }, 9000); // Thời gian trễ, ở đây là 2000ms (2 giây)

    return () => {
      clearTimeout(timeout);
    };
  }, []);


  useEffect(() => {
    const timeout = setTimeout(() => {
      setshowPosterGift(true);
    }, 10000); // Thời gian trễ, ở đây là 2000ms (2 giây)

    return () => {
      clearTimeout(timeout);
    };
  }, []);


  //showPosterGift

  return (




    <Layout>

      <main className="relative bg-gray-300">
        <div style={{ height: '0px', top: '-100px' }}>
          {/* thể hiện rằng có kết nối thì in ra */}
          <ConnectionState isConnected={isConnected} />
          <Events events={fooEvents} />
          <ConnectionManager />
          <MyForm />
        </div>

        <NovaHeader />
   

        {/*     <AiOutlineStar className="" size={44} style={{ color: "black" }}/>
           <BsStarFill className="" size={44} style={{ color: "yellow" }} /> */}


     { (finshHeard) && <div>
        {showIntroVideo && <NovaIntroVideo linkvideo = "https://www.youtube.com/embed/4oo9F_y2_T0" type = {0} linkimg = "/images/video-intro.png"/>}

        <div style={{ marginTop: '10px' }}>
        {/* <ImageView imageUrl="/img/dacsan.jpg" /> */}
        {showIntroVideo && <NovaIntroVideo linkvideo = "https://www.youtube.com/embed/PIKQ9lDofoQ" type = {1} linkimg = "/img/dacsan.jpg" />}

        {/* https://www.youtube.com/shorts/lKIxBP4K4O4 */}
        </div>
        
       {/* bộ sưu tập hình ảnh */}
        {showMemories && <NovaMemories />}

        {showMemories && <Nhahang />}
        {showMemories && <Baotag />}
        {/*  Nơi show danh sách các căn villa */}
        {showBookRoom && <NovaBookRoom />}

        {/* <Banner /> */}
        {showBookRoom && <NovaBookhotel />}


        {/*  {showRegisterForm && <NovaRegisterForm />} */}
        <div style={{ height: '150px' }}></div>

        <div style={{ height: '100%', backgroundColor: 'white' }}>
          {showServices && <NovaServices />}
        </div>

        {showInformation && <NovaInformation />}

       


        <div style={{ height: '100%', backgroundColor: 'white' }}>
          {showPosterGift && <NovaPosterGift />}
          { (tenanhgia.key && showPosterGift) && <ImageView imageUrl={"/img/"+tenanhgia.namegia} />}
        </div>
        </div>};
    


        {/* <Article /> */}




      </main>
    </Layout >





  )
}

export default App
