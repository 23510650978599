export function WC(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 512 512"
      {...props}
    >
      <path
        fill="currentColor"
        d="M132 139.824a61.912 61.912 0 1 0-61.912-61.912A61.981 61.981 0 0 0 132 139.824ZM132 48a29.912 29.912 0 1 1-29.912 29.912A29.947 29.947 0 0 1 132 48Zm44 104H88a48.053 48.053 0 0 0-48 48v152h32v144h120V352h32V200a48.053 48.053 0 0 0-48-48Zm16 168h-32v144h-56V320H72V200a16.019 16.019 0 0 1 16-16h88a16.019 16.019 0 0 1 16 16Zm178.088-180.176a61.912 61.912 0 1 0-61.912-61.912a61.981 61.981 0 0 0 61.912 61.912Zm0-91.824a29.912 29.912 0 1 1-29.912 29.912A29.947 29.947 0 0 1 370.088 48Zm55.671 145.354a61.586 61.586 0 0 0-115.833-1.392L248 357.1V400h64v96h104v-96h64v-50.7ZM448 368h-64v96h-40v-96h-64v-5.1l59.889-159.7a29.585 29.585 0 0 1 55.645.669L448 354.7Z"
      ></path>
    </svg>
  )
}
