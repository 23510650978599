
/* 
cách bọc App mẹ để biến global chạy khắp chương trình :
import { ContextProvider } from "./context_global/Context";
 <ContextProvider>
  //App mẹ ở đây
 </ContextProvider >

///////////

cách sử dụng :
import { useState, useEffect, useContext } from "react";
import { Context } from "../../../context_global/Context";
 const { timewaitVILLA, settimewaitVILLA } = useContext(Context);
const { imght, setimght } = useContext(Context);
*/


// Lưu ý rằng mở nó trên VS CODE để dễ đọc
/////////////////////////// file :  Context.js begin
import React, { createContext, useState, useEffect } from "react";


// xem ở (2) để biết là ta cung cấp dữ liệu cho một component bất kỳ là con của App
export const Context = createContext(null); // biến này sử dụng để cung cấp cho component khác. ( lưu ý nó sử dụng như bên dưới : Context.Provider)


export const ContextProvider = (props) => { // biến này dùng để bọc cái App lại để truyền toàn phần  xem (1)

   const  moveElementToTop = (datax,nameToMove) => {
    const updatedData = [...datax];
    const index = updatedData.findIndex(item => item.villaName === nameToMove);

    if (index !== -1) {
      const elementToMove = updatedData.splice(index, 1)[0]; // loại bỏ phần tử tại chỉ số : index, lưu lại mảng bị bỏ : elementToMove
      updatedData.unshift(elementToMove); // chèn : elementToMove vào đầu mảng: updatedData
     //console.log('DDDDDDDDDDDDDDDDDDDDDDD--->')
      //return updatedData;
     // setData(updatedData);
    }
    return updatedData;
  };


    // global biến toàn cục là một chuỗi
    const [timewaitVILLA, settimewaitVILLA] = useState(false);
    const [imght, setimght] = useState("");
    const [render_canban, setrender_canban] = useState(false);

    const [finshHeard, setfinshHeard] = useState(false);

    useEffect(() => {
    
      
      }, []);
    //////////////////////////////////////




/*
 các giá trị là các biến global chạy toàn chương trình
 lưu ý các giá trị : giatri1, nó có thể là một callback funtion
 bên dưới là các giá trị cần cung cấp để nó chạy toàn cục
*/
const contextValue = {timewaitVILLA, settimewaitVILLA, imght, setimght, render_canban, setrender_canban, moveElementToTop,finshHeard ,setfinshHeard}; //(3)


    return (
       <Context.Provider value={contextValue}>{props.children}</Context.Provider>
     );
   };
