'use client'
import { socket } from '../../../socket_server/socket';

// import dấu mũi tên xuống để người dùng nhấp vào thêm sản phẩm 
import { ArrowDown } from '../../../icons/ArrowDown'

import  { useContext } from 'react';
import { Context } from "../../../context_global/Context";
//J:\data\website\web_nova\client_web\src\components\Nova\Bookhotel\index.jsx
import { useEffect, useState } from 'react'

// những thông tin liên quan đến villa cụ thể
import { RoomItem } from './RoomItem'

import './Infor.css'

/* Xác định ban đầu có 3 sản phẩm được hiển thị */
const ITEM_PER_PAGE = 3;







export const NovaBookhotel = () => {
  const { render_canban, setrender_canban, moveElementToTop } = useContext(Context);

/*   let item0 = {
    price: "120000",
    hide: false,
    code: 'ESTELLA VILLA 2.09',
    villaName: 'ESTELLA VILLA',
    villaPath: 'estella-villa',
    rooms: 2,
    wc: 3,
    area: 300,
    beds: 4,
    people: [4, 6],
    swimmingPool: false,
    giftParty: false,
    description:
      'Estella Villa là một biểu tượng của sự sang trọng và tiện nghi, đặt trong khuôn viên tuyệt đẹp của một khu nghỉ dưỡng xa hoa. Villa này là một nơi lý tưởng để trốn khỏi cuộc sống đô thị ồn ào và tận hưởng không gian riêng tư và yên bình.',
    images: {
      length: 26,
      path: 'estella-villa'
    },
    villaAddress : ""
  }

    let item1 = {
      price: "120000",
      hide: false,
      code: 'ESTELLA VILLA 2.09',
      villaName: 'ESTELLA VILLA',
      villaPath: 'estella-villa',
      rooms: 2,
      wc: 3,
      area: 300,
      beds: 4,
      people: [4, 6],
      swimmingPool: false,
      giftParty: false,
      description:
        'Estella Villa là một biểu tượng của sự sang trọng và tiện nghi, đặt trong khuôn viên tuyệt đẹp của một khu nghỉ dưỡng xa hoa. Villa này là một nơi lý tưởng để trốn khỏi cuộc sống đô thị ồn ào và tận hưởng không gian riêng tư và yên bình.',
      images: {
        length: 26,
        path: 'estella-villa'
      },
      villaAddress : ""
    }
 */
  


  /* Đây là mảng data chính được đưa vào đầu tiên */
  const [DATA_ROOMS, setDATA_ROOMS] = useState([])
  const [rend, setrend] = useState(false)


  /* set lại độ dài hiển thị khi có lệnh */
  const [itemLength, setItemLength] = useState(ITEM_PER_PAGE)

  /* gán lại danh sách render sản phẩm khi độ dài của sản phẩm render có sự thay đổi */
  const [dataRender, setDataRender] = useState(DATA_ROOMS.slice(0, itemLength))


/* khi người dùng nhấp vào xem thêm sản phẩm thì đọc và set lại độ dài sản phẩm gốc */
  const handleNext = () => {
    setItemLength(DATA_ROOMS.length)
  }


/*   // input dữ liệu vào cho data toàn danh sách
  useEffect(() => {
    setDATA_ROOMS([item0, item1, item1, item1, item1]);
  }, []) */


  useEffect(() => {
    /* Khi độ dài sản phẩm trình chiếu thay đổi thì danh sách nó cũng sẽ được cập nhật trên màn hình */
    setDataRender(DATA_ROOMS.slice(0, itemLength))
  }, [itemLength])


  if (1 == 0){
// sau thời gian 5 giây nó sẽ render tạm 3 danh sách
const doiketqua = () => {
  setDataRender(DATA_ROOMS.slice(0, itemLength))
};
setTimeout(doiketqua,2000);
  }



// nơi import thông tin data mảng để render ra begin
useEffect(() => {
  socket.emit("allhotel", {  }); // gửi về máy chủ để nhận danh sách phòng phục vụ mục đích render
  socket.on('allhotel', msg => { // sự kiện server gửi dữ liệu đến
   console.log("HOTEL NHẬN TỪ MÁY CHỦ");
   
   console.log(msg.allhotel) ;

   let data = msg.allhotel;
   //rend
   
   if (data[0].villaPath != '') { setrend(true);setrender_canban(true);};
   
// đưa một phần tử có villaName bằng tên nào đó lên đầu index  = 0 begin
  //  const moveElementToTop = (nameToMove) => {
  //   const updatedData = [...data];
  //   const index = updatedData.findIndex(item => item.villaName === nameToMove);

  //   if (index !== -1) {
  //     const elementToMove = updatedData.splice(index, 1)[0]; // loại bỏ phần tử tại chỉ số : index, lưu lại mảng bị bỏ : elementToMove
  //     updatedData.unshift(elementToMove); // chèn : elementToMove vào đầu mảng: updatedData

  //     msg.allhotel = updatedData;
  //    // setData(updatedData);
  //   }
  // };
  // đưa một phần tử có villaName bằng tên nào đó lên đầu index  = 0 end

 
  //console.log('LLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLL', data )
 try{

  const arrB = data.map(item => ({// tạo mảng arrB theo yêu cầu các phần tử 
    villaName: item.villaName,
    stt: item.people[1]
}));

arrB.sort((a, b) => b.stt - a.stt);// sắp xếp mảng arrB lại theo thứ tự giảm dần của stt
//console.log('LLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLL', arrB );
for(let ic = 0; ic < arrB.length ; ic++){

  msg.allhotel = moveElementToTop(msg.allhotel,arrB[ic].villaName);// sắp xếp thứ tự render sản phẩm

}

//console.log('LLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLL', msg.allhotel );
  
  setDataRender(msg.allhotel.slice(0, itemLength)); // mảng dùng để render ở trang chủ
  setDATA_ROOMS(msg.allhotel);// mảng đầy đủ
 }catch(uu){
  setDataRender([]);
  setDATA_ROOMS([]);
 };
 
  });
  
}, [])
// nơi import thông tin data mảng để render ra end


//J:\data\website\web_nova\client_web\src\components\Nova\Bookhotel\index.jsx
let canhle = "text-center";
//dataRender.length
//text-center text-left
if (rend) {
  // <span className="red-text">HOTEL</span>
  return (
    <section className="w-full mb-6 bg-gray-300"
    id="HOTEL VIEW"
    >
      <div className="max-w-screen-lg m-auto mt-12 md:mt-8">
      <h5 className={"font-bold text-sm md:text-xl " + canhle}>
      GIỎ HÀNG CHUYỂN NHƯỢNG GIÁ RẺ NHẤT THÁNG 07/2024 
      </h5>
      <h5 className="font-bold text-sm md:text-xl text-center">KHU THE TROPICANA - NOVAWORLD HỒ TRÀM</h5>
      <h5 style={{fontSize : '10px'}} className="font-bold text-sm md:text-3sm text-center">Quản lý tải sản kép kín 1 đầu mối - giúp tăng giá trị thu nhập cho nhà đầu tư</h5>
      {/* <h5 className="font-bold text-sm md:text-xl text-center">QUẢN LÝ MUA BÁN NHÀ - QUẢN GIA, CHO THUÊ CHĂM SÓC HẰNG NGÀY - SỬA CHỮA DEFECT</h5> */}
      </div>
      <div className="max-w-screen-sm m-auto mt-4 ">
      {dataRender.map((item, index) => {
          return <RoomItem key={index} item={item} />
        })} 
        {DATA_ROOMS.length !== dataRender.length && (
          <div
            className="mt-4 text-center flex gap-0 flex-col justify-center items-center hover:text-blue-500 hover:cursor-pointer"
            onClick={() => handleNext()}
          >
            Xem thêm Căn trống <ArrowDown className="w-9 h-auto" />
          </div>
        )}
      </div>
    </section>
  )

        }






}


