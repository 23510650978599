import React from 'react';
//className="my-12"
// Định nghĩa một component tên là ImageView
//style={{ backgroundColor: 'blue' }}
//
function ImageView(props) {
  return (
    <section id="VIEW GIA" >
      <div className="max-w-screen-lg m-auto" >


        <div style={{  textAlign: 'center' }}>
          {/* Sử dụng thẻ <img> để hiển thị tấm ảnh */}
          <img src={props.imageUrl} alt="Ảnh" style={{ maxWidth: '100%', height: 'auto', display: 'inline-block' }} />
        </div>


      </div>
    </section>
  );
}

export default ImageView;