'use client'
import { FloatButton } from 'antd'
import React from 'react'
import { Contacts } from '../Contacts'
import { Link } from 'react-router-dom'
import anhlogo from '../../../images/logo.png'

import facebook from '../../../fonts/facebook.svg'
import youtube from '../../../fonts/youtube.svg'
import inta from '../../../fonts/instagram.svg'
import titok from '../../../fonts/tiktok-icon.svg'
import MyComponent from './MapComponent';
import { relativeTimeThreshold } from 'moment/moment'
// C:\Users\pchai\Desktop\novaland-ho-tram-main-cra-js\src\components\Nova\Footer\index.jsx

// ../../../images/logo.png
//C:\Users\pchai\Desktop\novaland-ho-tram-main-cra-js\src\components\Nova\Footer\index.jsx
export const NovaFooter = () => {
  return (
    <>
      <footer className="bg-gray-100 pb-24">
        <div className="mx-auto max-w-5xl px-4 py-16 sm:px-6 lg:px-8">
          <div className="flex justify-center text-teal-600">
          
            <Link to="/" className="flex gap-2 items-center">
              <div className=" rounded">
              <img src={anhlogo} alt="Workflow" width={88} />
              </div>
              <h1 className="text-2xl">Happy Stay Resort</h1>
            </Link>
          </div>
          <div className="flex justify-center">
            <div>
            <a
              href="https://maps.app.goo.gl/UmunoMsgVueviugaA"
              target="_blank"
              rel="noreferrer"
              className="mx-auto mt-6 text-center leading-relaxed text-gray-500 hover:text-blue-500"
            >
              <div style={{color : 'blue', fontWeight: 'bold'}}>Đia chỉ nhà hàng tại Tropicana:</div> 
              <div style={{color : 'black', fontWeight: 'bold'}}>Shophouse  2-2.09, khu Tropicana, đường ven biển Bình Châu, huyện Xuyên Mộc, tỉnh Bà Rịa - Vũng Tàu.</div>
            </a>
            </div>
           
            <div>
            <a
              href="https://maps.app.goo.gl/UmunoMsgVueviugaA"
              target="_blank"
              rel="noreferrer"
              className="mx-auto mt-6 text-center leading-relaxed text-gray-500 hover:text-blue-500"
            >
              <div style={{color : 'green', fontWeight: 'bold'}}>Địa chỉ nhà hàng tại Wonderland:</div> 
              <div style={{color : 'gray', fontWeight: 'bold'}}>Shophouse A16, khu Wonderland, đường ven biển Phước Thuận, huyện Xuyên Mộc, tỉnh Bà Rịa - Vũng Tàu</div>
            </a>
            </div>

          </div>
          <div className="flex justify-center mt-12">
            <p>
              <span className="uppercase text-gray-500">Liên hệ với chúng tôi</span>
            
              <a
                href="tel:0706257968"
                className="mt-2 block text-2xl text-gray-900 hover:opacity-75 sm:text-3xl"
                style={{display:'flex', justifyContent : 'center'}}
              >
                0706 25 79 68
              </a>
            </p>
         
          </div>
          <ul className="mt-5 flex justify-center gap-6 md:gap-8">
            {[
              {
                icon: facebook,
                name: 'Facebook',
                link: 'https://www.facebook.com/happystayresort'
              },
              {
                icon: inta,
                name: 'Instagram',
                link: '/'
              },
              // {
              //   icon: '/fonts/zalo.svg',
              //   name: 'Zalo',
              //   link: '/'
              // },
              {
                icon: youtube,
                name: 'Youtube',
                link: 'https://www.youtube.com/channel/UCtBS1jBH0peggriRDlm4gpA'
              },
              // {
              //   icon: '/fonts/pinterest.svg',
              //   name: 'Pinterest',
              //   link: '/'
              // },
              {
                icon: titok,
                name: 'Tiktok',
                link: 'https://www.tiktok.com/@happystayresort'
              }
            ].map((m, i) => {
              return (
                <li key={i}>
                  <a
                    href={m.link}
                    rel="noreferrer"
                    target="_blank"
                    className="text-gray-700 transition hover:text-gray-700/75"
                  >
                    <span className="sr-only">{m.name}</span>
                    <img src={m.icon} alt="" width={48} className="h-auto" />
                  </a>
                </li>
              )
            })}
          </ul>
    
        </div>
         <div style={{  justifyContent: 'center', width : '100%', position : 'relative' }}>
          <MyComponent />
          </div>
      </footer>
      <FloatButton.BackTop style={{ right: 80 }} />
      <Contacts />
    </>
  )
}
