import { Col, DatePicker as AntDatePicker, Form, Input, Row, InputNumber, message } from 'antd'
import dayjs from 'dayjs'
import { Send } from 'src/icons/Send'
import { useState } from "react";
//let Successfulorder = "Đã đặt hàng thành công! Chúng tôi sẽ liên hệ sớm";
//let Successfulorder = "Đăng ký ngay";
let textColor = 'black';
const DatePicker = AntDatePicker
// eslint-disable-next-line arrow-body-style
const disabledDate = (current) => {
  // Can not select days before today and today
  return current && current < dayjs().endOf('day')
}

export const RegisterForm = ({ className }) => {

  const [Successfulorder, setSuccessfulorder] = useState("Đăng ký ngay");
  const onFinish = (values) => {
    if (Successfulorder === "Đăng ký ngay") {
      setSuccessfulorder("Đang gửi...")
      //     let sdt = "0933333325";
      // let ten = "Nguyễn Đức Hải";
      // let ngay_batdau = "1/7/2023";
      // let ngay_ketthuc = "10/7/2023";
      // let nguoi_lon = "5";
      // let tre_em = "3"
      // let tin_nhan = "Đặt phòng tốt cho tôi";
      // let get_url = "https://tuongdoirong.com/api/node.php?f=inst&database=happystayresort&mytable=khachbook&cot=sdt,ten,date_start,date_end,nguoi_lon,tre_em,tin_nhan&gt='"+sdt+"','"+ten+"','"+ngay_batdau+"','"+ngay_ketthuc+"','"+nguoi_lon+"','"+tre_em+"','"+tin_nhan+"'";
      try {
        fetch(
          `https://tuongdoirong.com/api/node.php?f=inst&database=happystayresort&mytable=khachbook&cot=sdt,ten,date_start,date_end,nguoi_lon,tre_em,tin_nhan&gt=` +
          `"${values.phone}"` +
          ',' +
          `"${values.fullName}"` +
          ',' +
          `"${dayjs(values.startDate).format('D/M/YYYY')}"` +
          ',' +
          `"${dayjs(values.endDate).format('D/M/YYYY')}"` +
          ',' +
          `"${values.adult}"` +
          ',' +
          `"${values.children}"` +
          ',' +
          `"${values.note}"`
        )
          .then((response) => response.json())
          .then((data) => {
            if (data.status === 'success') {
              message.success('Liên hệ thành công!, Xin hãy chờ phản hồi sớm nhất từ chúng tôi');
              console.log("Đã đặt hàng thành công")
            }
          })
          .catch((error) => {
            message.error('Đã có lỗi xảy ra, vui lòng thử lại sau')
          })
          .finally(() => {
            console.log('Done')
            message.success('Liên hệ thành công!, Xin hãy chờ phản hồi sớm nhất từ chúng tôi');
            //Successfulorder = "Đã đặt hàng thành công! Chúng tôi sẽ liên hệ sớm";
            setSuccessfulorder("Đã đặt hàng thành công! Chúng tôi sẽ liên hệ sớm");
            textColor = 'green';
            setTimeout(function () { setSuccessfulorder("Đăng ký ngay"); textColor = 'black'; }, 10000)
          })
      } catch (error) {
        message.error('Đã có lỗi xảy ra, vui lòng thử lại sau')
      }

    }

  }

  return (
    <>
      <div className={`flex flex-col ${className}`}>
        <div className="pb-1 md:pb-3 font-thena">
          <h2 className="text-4xl md:text-6xl font-thena text-orange-500 font-bold drop-shadow-lg ">
            Đặt Phòng Ngay
          </h2>
          <h2 className={'text-4xl md:text-6xl text-right pt-2 drop-shadow-xl'}>
            nhận ngay giá ưu đãi
          </h2>
        </div>
        <Form name="basic" labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} onFinish={onFinish}>
          <Row gutter={[8, 0]}>
            <Col span={12}>
              <Form.Item
                label="Số điện thoại"
                name="phone"
                rules={[
                  {
                    required: true,
                    message: 'Vui lòng nhập!'
                  },
                  {
                    pattern: new RegExp(/(84|0[3|5|7|8|9])+([0-9]{8})\b/),
                    message: 'Vui lòng nhập đúng số điện thoại!'
                  }
                ]}
              >
                <Input id="FORMDANGKY_NAME" />

              </Form.Item>
            </Col>


            <Col span={12}>
              <Form.Item
                label="Họ và tên"
                name="fullName"
                rules={[
                  {
                    required: true,
                    message: 'Vui lòng nhập!'
                  }
                ]}
              >
                <Input />
              </Form.Item>
            </Col>


            <Col span={12}>
              <Form.Item
                label="Ngày nhận phòng"
                name="startDate"
                rules={[
                  {
                    required: true,
                    message: 'Vui lòng nhập!'
                  }
                ]}
              >
                <DatePicker
                  style={{ width: '100%' }}
                  format={'DD/MM/YYYY'}
                  disabledDate={disabledDate}
                />
              </Form.Item>
            </Col>


            
            <Col span={12}>
              <Form.Item
                label="Ngày trả phòng"
                name="endDate"
                rules={[
                  {
                    required: true,
                    message: 'Vui lòng nhập!'
                  }
                ]}
              >
                <DatePicker
                  style={{ width: '100%' }}
                  format={'DD/MM/YYYY'}
                  disabledDate={disabledDate}
                />
              </Form.Item>
            </Col>


            <Col span={12}>
              <Form.Item
                label="Người lớn"
                name="adult"
                rules={[
                  {
                    required: true,
                    message: 'Vui lòng nhập!'
                  }
                ]}
              >
                <InputNumber style={{ width: '100%' }} />
              </Form.Item>
            </Col>


            <Col span={12}>
              <Form.Item label="Trẻ em" name="children">
                <InputNumber style={{ width: '100%' }} />
              </Form.Item>
            </Col>


            <Col span={24}>
              <Form.Item label="Để lại lời nhắn" name="note">
                <Input.TextArea />
              </Form.Item>
            </Col>


            <Col span={24} className="flex justify-center">
              <button className="inline-block rounded bg-gradient-to-r from-pink-500 via-red-500 to-yellow-500 p-[2px] hover:text-white focus:outline-none focus:ring active:text-opacity-75 ">
                <span className="block rounded-sm bg-white px-8 py-2 text-sm font-medium hover:bg-transparent  transition-all duration-[200ms] gap-1">
                  <Send className="inline-block w-5 h-auto" /> <span style={{ color: textColor }}>{Successfulorder}</span>
                </span>
              </button>
            </Col>



          </Row>
        </Form>
      </div>
    </>
  )
}
