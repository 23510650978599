import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import axios from 'axios';

const UploadImages = () => {
  const [shouldUpload, setShouldUpload] = useState(true);

  const onDrop = useCallback(async (acceptedFiles) => {
    if (!shouldUpload) {
      // Nếu shouldUpload là false, không gửi dữ liệu lên máy chủ
      console.log('Upload is disabled.');
      return;
    }
//J:\data\website\web_nova\client_upload\src\UploadImages.jsx
    // Tạo FormData để đóng gói các tệp hình ảnh
    const formData = new FormData();
    
    acceptedFiles.forEach((file, index) => {
      //change_key_upload
      formData.append(`key_file_save_anh_tongquat`, file); // Đặt tên cho từng tấm ảnh  : -${index}
     
    });

    try {
      // Gửi yêu cầu POST đến máy chủ Node.js
      //http://localhost:5001
      const response = await axios.post('/key_request_save_anh_tongquat', formData, { //change_key_upload
      
        headers: {
          'Content-Type': 'multipart/form-data', // Đảm bảo đúng kiểu dữ liệu
        },
      });

      console.log(response.data);
    } catch (error) {
      console.error('Error uploading images:', error);
    }
  }, [shouldUpload]);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: 'image/*', // Chấp nhận các tệp hình ảnh
  });

  const toggleUpload = () => {
    setShouldUpload(!shouldUpload); // Đảo ngược giá trị của shouldUpload khi bạn muốn bật/tắt việc gửi dữ liệu
  };

  return (
    <div>
      <div {...getRootProps()} className="dropzone">
        <input {...getInputProps()} />
        <p>Thêm Ảnh</p>
      </div>
      {/* <button onClick={toggleUpload}>
        {shouldUpload ? 'Tắt tải lên' : 'Bật tải lên'}
      </button> */}
    </div>
  );
};

export default UploadImages;

