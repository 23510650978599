'use client'

import React from 'react'
import { Link } from 'react-router-dom'
import logoanh from '../../../images/logo.png'
import { useState, useEffect, useContext } from "react";
import { Context } from "../../../context_global/Context";
import './Topheader.css'
import { v4 as uuidv4 } from 'uuid';


// ../../../images/logo.png
//C:\Users\pchai\Desktop\novaland-ho-tram-main-cra-js\src\components\Nova\TopHeader\index.jsx

export const NovaTopHeader = () => {
  const { timewaitVILLA } = useContext(Context);

  const [ solan_, setsolan_  ] = useState(0);

  const { render_canban, setrender_canban } = useContext(Context);
  const [bodyWidth, setBodyWidth] = useState(window.innerWidth);

  const [w_button, setw_button] = useState( { w : '60px', flex : 'column' });
  const [c_wbutton, setc_wbutton] = useState({ w : '70px', flex : 'column' });
  const pathname = window.location.pathname;



console.log('ĐƯỜNG DÂN PATH: ', pathname);

const [name, setName] = useState(() => {// giá trị này theo giá trị : timewaitVILLA  --> nhưng có sự config
  const savedName = localStorage.getItem('namef5');
  if (savedName){// nếu tồn tại giá trị đã lưu xuống local
   let value_local = JSON.parse(savedName);
    if (value_local.key == false){// khi local đang lưu trữ dữ liệu sai
      setsolan_( value_local.solan + 1 );
      return timewaitVILLA;
    }
    if (value_local.key == true){
      localStorage.setItem('namef5', JSON.stringify({ key : false, solan : value_local.solan }));
      if (pathname != '/'){// không áp dụng cho trang chủ
      return true;
      }else{ // khi đang thao tác ở trang chủ
        return timewaitVILLA;
      }


    }
   

    
  }else{// khi không tồn tại giá trị ở local
    return timewaitVILLA;
  };

});



  if (localStorage.getItem('uuid') === null) {
    const uniqueId = uuidv4();
    localStorage.setItem("uuid", uniqueId);
  }
// 

const update_setw_button = (newWidth, flexvalue) => {
  setw_button((prevState) => ({
    ...prevState,
    w: newWidth,
    flex: flexvalue
  }));
  setc_wbutton((prevState) => ({
    ...prevState,
    w: newWidth,
    flex: flexvalue
  }));

};
const update_setc_wbutton = (newWidth, flexvalue) => {
  setc_wbutton((prevState) => ({
    ...prevState,
    w: newWidth,
    flex: flexvalue
  }));

};

  const handleResize = () => {
    setBodyWidth(window.innerWidth);
    console.log('thay đổi độ rộng: ', window.innerWidth);
    if (window.innerWidth <= 470){
      // w_button = '40px';
      //setw_button('40px');
      update_setw_button('40px', 'row' )
      update_setc_wbutton('50px', 'row')

    };
    if (window.innerWidth > 470 && window.innerWidth <= 490){
      // w_button = '40px';
      update_setw_button('60px', 'row' )
      update_setc_wbutton('70px', 'row')
    };
  
    if (window.innerWidth > 490){
      // w_button = '40px';
      update_setw_button('80px', 'column' )
      update_setc_wbutton('90px', 'column')
    };
    //470

  };


  useEffect(() => {
    if (pathname == '/'){// chỉ áp dụng ở trang chủ
    setName(timewaitVILLA);
    };
  }, [timewaitVILLA]);



useEffect(() => {

  if (window.innerWidth <= 470){
    // w_button = '40px';
    update_setw_button('40px', 'row' )
    update_setc_wbutton('50px', 'row')
  };

  if (window.innerWidth > 470 && window.innerWidth <= 490){
    // w_button = '40px';
    update_setw_button('60px', 'row' )
    update_setc_wbutton('70px', 'row')
  };

  if (window.innerWidth > 490){
    // w_button = '40px';
    update_setw_button('80px', 'column' )
    update_setc_wbutton('90px', 'column')
  };

  window.addEventListener('resize', handleResize);

  // Cleanup function to remove the event listener
  return () => {
    window.removeEventListener('resize', handleResize);
  };
}, []);



useEffect(() => {
  const handleBeforeUnload = (event) => {
    // Thực hiện các hành động trước khi tải lại trang
    console.log('Đã tải lại trang : ');

    const savedName = localStorage.getItem('namef5');
    if (!savedName){
    localStorage.setItem('namef5', JSON.stringify({ key : true, solan : 0 })); // lưu xuống local cho thấy rằng đã có tải lại trang
    }
     if (savedName){
      let _local = JSON.parse(savedName);
      localStorage.setItem('namef5', JSON.stringify({ key : true, solan : _local.solan + 1 })); // lưu xuống local cho thấy rằng đã có tải lại trang
      }

   
    // setTimeout(()=>{
    //   setreder_butt(true);

    // },4000);
       
    // Nếu muốn hiển thị thông báo xác nhận
    //event.preventDefault();
    event.returnValue = '';
  };

  window.addEventListener('beforeunload', handleBeforeUnload);

  return () => {
    window.removeEventListener('beforeunload', handleBeforeUnload);
  };
}, []);



  return (
    <div className="fixed top-0 left-0 z-50 w-full bg-gradient-to-r from-green-400 to-blue-400 shadow-xl px-4 md:px-0">


      {/* fixed top-0 left-0 z-50 w-full bg-gradient-to-r from-green-400 to-blue-400 shadow-xl px-4 md:px-0 */}
      <div className="max-w-screen-md m-auto flex justify-between items-center">
        <a href="tel:0706257968" rel="">
          {/* gap-3  p-1 */}
          <div className="flex">
            <div className="w-8 md:w-10">
              <svg
                xmlnsXlink="http://www.w3.org/1999/xlink"
                width="100%"
                height="100%"
                viewBox="0 0 24 24"
                // fill="rgba(255,87,34,1)"
                className="fill-yellow-400"
              >
                <path d="M6.62,10.79C8.06,13.62 10.38,15.94 13.21,17.38L15.41,15.18C15.69,14.9 16.08,14.82 16.43,14.93C17.55,15.3 18.75,15.5 20,15.5A1,1 0 0,1 21,16.5V20A1,1 0 0,1 20,21A17,17 0 0,1 3,4A1,1 0 0,1 4,3H7.5A1,1 0 0,1 8.5,4C8.5,5.25 8.7,6.45 9.07,7.57C9.18,7.92 9.1,8.31 8.82,8.59L6.62,10.79Z" />
              </svg>
            </div>
            <div className="font-bold text-white ">
              <h1 className="text-sm md:text-2xl">0706 25 79 68</h1>
              <h3 className="text-sm md:text-xl  text-yellow-400 font-bold">Hotline</h3>
              {/* text-base md:text-xl */}
            </div>
          </div>
        </a>

        {(name) && <button style={{ display: 'flex', top: '100px', fontSize: '10px', height: '30px', alignItems: 'center', textAlign: 'center', width: w_button.w, flexDirection : w_button.flex }} className="text-white px-2 py-2 rounded font-semibold border border-white"
          onClick={() => {
            document.getElementById('VILLA VIEW')?.scrollIntoView({
              behavior: 'smooth',
              block: 'start',
              inline: 'center'
            })
            setTimeout(() => {
              document.getElementById('VILLA VIEW')?.focus()
            }, 1000)
          }}
        >CHO THUÊ</button>}



        {/* style={{color : 'white' , width : '80px', height : '50px' , fontSize : '14px'}} */}
        {(name && render_canban) && <button style={{display: 'flex', top: '100px', fontSize: '10px', height: '30px', alignItems: 'center',textAlign: 'center', width: w_button.w, flexDirection : w_button.flex }} className="text-white px-2 w-50 py-2 rounded font-semibold border border-white"
          onClick={() => {
            document.getElementById('HOTEL VIEW')?.scrollIntoView({
              behavior: 'smooth',
              block: 'start',
              inline: 'center'
            })
            setTimeout(() => {
              document.getElementById('HOTEL VIEW')?.focus()
            }, 1000)
          }}
        >CẦN BÁN</button>}

      { pathname == '/' && <button style={{display: 'flex', top: '100px', fontSize: '10px', height: '30px', alignItems: 'center', textAlign: 'center', width: c_wbutton.w, flexDirection : c_wbutton.flex }} className="text-white px-1 py-2 rounded font-semibold border border-white"
          onClick={() => {
            document.getElementById('VIEW GIA')?.scrollIntoView({
              behavior: 'smooth',
              block: 'start',
              inline: 'center'
            })
            setTimeout(() => {
              document.getElementById('VIEW GIA')?.focus()
            }, 1000)
          }}
        >TRẢI NGHIỆM</button>}

        <Link to="/">
          <img src={logoanh} alt="" className="drop-shadow-2xl w-16 h-16" />
        </Link>
      </div>
    </div>
  )
}

