export const Tivi = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
      enableBackground="new 0 0 100 100"
      xmlSpace="preserve"
      preserveAspectRatio="none"
      width="30px"
      height="30px"
      className=""
      fill="rgba(0, 102, 171, 1)"
      {...props}
    >
      <g>
        <path d="M73.556,22.002H50.363l8.84-14.889c0.204-0.47-0.013-1.017-0.483-1.22c-0.47-0.204-1.017,0.012-1.221,0.483l-9.012,15.179   l-5.128-10.293c-0.229-0.459-0.786-0.646-1.245-0.417c-0.459,0.229-0.646,0.786-0.417,1.245l4.938,9.913H27.325   c-2.285,0-4.137,1.852-4.137,4.137v30.527c0,2.285,1.852,4.137,4.137,4.137h46.231c2.284,0,4.137-1.852,4.137-4.137V26.139   C77.693,23.854,75.84,22.002,73.556,22.002z M68.482,26.538h4.503c0.511,0,0.925,0.414,0.925,0.925c0,0.51-0.414,0.924-0.925,0.924   h-4.503c-0.511,0-0.924-0.414-0.924-0.924C67.558,26.952,67.971,26.538,68.482,26.538z M64.166,53.796   c0,1.364-1.105,2.471-2.47,2.471H28.952c-1.364,0-2.471-1.106-2.471-2.471V29.008c0-1.364,1.106-2.47,2.471-2.47h32.744   c1.364,0,2.47,1.106,2.47,2.47V53.796z M66.944,44.579c-0.68,0-1.229-0.55-1.229-1.229c0-0.678,0.55-1.229,1.229-1.229   c0.678,0,1.229,0.55,1.229,1.229C68.172,44.029,67.622,44.579,66.944,44.579z M70.733,44.579c-0.678,0-1.229-0.55-1.229-1.229   c0-0.678,0.551-1.229,1.229-1.229c0.68,0,1.229,0.55,1.229,1.229C71.961,44.029,71.413,44.579,70.733,44.579z M70.734,38.552   c-1.928,0-3.49-1.562-3.49-3.49c0-1.927,1.563-3.489,3.49-3.489c1.927,0,3.488,1.562,3.488,3.489   C74.222,36.99,72.661,38.552,70.734,38.552z M74.524,44.579c-0.679,0-1.229-0.55-1.229-1.229c0-0.678,0.55-1.229,1.229-1.229   s1.229,0.55,1.229,1.229C75.752,44.029,75.203,44.579,74.524,44.579z" />
        <path d="M66.333,90.063L51.626,68.864l-0.079-0.113c-0.002-0.003-0.004-0.004-0.006-0.007c-0.05-0.071-0.106-0.138-0.17-0.199   c-0.002-0.001-0.003-0.003-0.005-0.005c-0.047-0.045-0.091-0.092-0.147-0.131c-0.167-0.115-0.351-0.186-0.538-0.218   c-0.02-0.003-0.039-0.004-0.058-0.007c-0.061-0.009-0.122-0.015-0.184-0.015s-0.123,0.006-0.184,0.015   c-0.019,0.003-0.039,0.004-0.058,0.007c-0.188,0.032-0.371,0.103-0.538,0.218c-0.055,0.039-0.1,0.086-0.146,0.131   c-0.002,0.002-0.004,0.004-0.006,0.005c-0.063,0.062-0.12,0.128-0.169,0.199c-0.002,0.002-0.004,0.004-0.006,0.007l-0.079,0.113   L34.547,90.063c-0.426,0.615-0.273,1.46,0.341,1.887c0.615,0.427,1.46,0.274,1.887-0.341L50.44,71.912l13.664,19.697   c0.428,0.615,1.271,0.768,1.887,0.341S66.758,90.678,66.333,90.063z" />
        <path d="M83.971,64.595c0,1.052-0.854,1.905-1.905,1.905H18.814c-1.052,0-1.905-0.854-1.905-1.905l0,0   c0-1.052,0.853-1.905,1.905-1.905h63.251C83.118,62.69,83.971,63.543,83.971,64.595L83.971,64.595z" />
      </g>
    </svg>
  )
}
