'use client'
import React from 'react'
import { ImageGallery } from 'src/components/ImageGallery';

import { useEffect } from 'react'; 
import { socket } from '../../../socket_server/socket';

//quay_lai
// const galleries = Array.from({ length: 16 }, (_, i) => ({
//   original: `/images/bosuutap/_${i + 1}.jpg`,
//   thumbnail: `/images/bosuutap/_${i + 1}.jpg`
// }))
let galleries = [];

export const NovaMemories = () => {
 // J:\data\website\web_nova\client_web\src\socket_server\socket.js
//client_web/src/components/Nova/Memories/index.jsx
  useEffect(() => { 
 
    let data = "xxx";
    // gửi mẫu qua server 
   socket.emit("send_ext_img",   {data} );
   
   // nhận câu trả lời từ server
   socket.on('send_ext_img', msg => { // có tin nhắn tới với key = 'chat message'
    // alert(msg.data)
    let galleries_kq = msg.extimg;
    // let galleries_json = JSON.stringify(galleries);
    // galleries_json = galleries_json.replace(/\\/g, '/');
    // galleries_json = galleries_json.replace(/duan/g, '');
 console.log("/////////////////////////anh")
 console.log(galleries_kq);
  galleries = Array.from({ length: galleries_kq.length }, (_, i) => ({
   original: `/images/bosuutap/` + galleries_kq[i].original,
   thumbnail: `/images/bosuutap/`+ galleries_kq[i].thumbnail
 }))


    //galleries = JSON.parse(galleries_json);
    //replace(/o/g, 'x');
     console.log("msg:", galleries);
    
   });
   return () => { // trả về sự kiện để có thể làm sạch lịch sử của nó
     //socket.off('Sendreceive', onConnect);
  
   };


 }, []);


if (galleries != []){
  return (
    <>
      <section className="w-full mt-10 px-4 md:px-0 bg-gray-300">
        <div className="max-w-screen-md m-auto font-thena">
          <h3 className="text-4xl md:text-7xl">Những khoảnh khắc</h3>
          <h3 className="font-quick-sand text-center italic text-xl md:text-2xl">khó quên tại</h3>
          <h2 className="text-4xl md:text-7xl text-right">Villa Novaworld Hồ Tràm</h2>
        </div>
        <div id="NovaMemories" className="max-w-screen-sm m-auto mt-4">
          <ImageGallery items={galleries} autoPlay />
        </div>
      </section>
    </>
  )
  }


}
