import React from 'react'

export const InfoSection = ({ title, description, image, isReverse, bgColor }) => {
  if (!title) return <> </>

  return (
    <section>
      <div className="mx-auto max-w-screen-2xl px-4 sm:px-4 md:px-6">
        <div className="grid grid-cols-1 md:grid-cols-2 ">
          <div className={`relative z-10 md:py-10 ${isReverse && 'md:order-2'}`}>
            <div className="relative h-48 sm:h-64">
              <img
                alt="House"
                src={image}
                className="absolute inset-0 h-full w-full object-cover"
              />
            </div>
          </div>
          <div className={`relative flex items-center ${bgColor}`}>
            <div
              className={`hidden md:absolute md:inset-y-0 md:block ${bgColor} md:w-16 ${
                isReverse ? '  md:-end-16' : ' md:-start-16'
              }`}
            />
            <div className="p-4 sm:p-4 md:p-8">
              <h2 className="text-xl font-bold sm:text-3xl">{title}</h2>
              <p className="mt-2 md:mt-4 text-gray-700 text-base ">{description}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
