import { BsStarFill } from "react-icons/bs";
import { AiOutlineStar } from "react-icons/ai";
import { BiSolidBowlHot } from "react-icons/bi";
import './sao.css'

export function Staryellow(props) {

   // let type_tex = 'rgb(27, 117, 252)';
    let type_tex = 'rgb(0, 0, 0)';
    let tex_ph = "";
    if (props.type == 0) {
    type_tex = 'rgb(27, 117, 252)';
    tex_ph = "Hồ Tràm - Rừng nguyên sinh"
    };

    return (

        <div className="container" style={{margin : 'auto'}}>

            

            <div className="" >
              <span style={{ color: type_tex }}> {props.tex}  </span> &nbsp; 
              <div>
              <span style={{ color: 'rgb(1, 39, 87)' }}>{tex_ph}</span>
              </div>
            
          
            </div>
       
            {/* <div className="tothucan" style={{position:'absolute' , display : 'flex', marginBottom: '0px'}}> { (props.type == 0) &&  <BiSolidBowlHot className="yellow-star"/> } </div> */}

         { (props.type == 1) &&  <div className="parent">
            <div className="star-container box child">
                <BsStarFill className="yellow-star" size={34} />
              {/*   <AiOutlineStar className="black-star" size={44} /> */}
            </div>

            <div className="star-container box child">
                <BsStarFill className="yellow-star" size={34} />
           {/*      <AiOutlineStar className="black-star" size={44} /> */}
            </div>

            <div className="star-container box child">
                <BsStarFill className="yellow-star" size={34} />
              {/*   <AiOutlineStar className="black-star" size={44} /> */}
            </div>

            <div className="star-container box child">
                <BsStarFill className="yellow-star" size={34} />
        {/*         <AiOutlineStar className="black-star" size={44} /> */}
            </div>

            </div> }
        

        </div>





    )
}
  // '.\icons\Staryellow'