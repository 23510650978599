import React from 'react'
import { Tivi } from './icons/Tivi'
import { Clean } from './icons/Clean'
import { Parking } from './icons/Parking'
import { Other } from './icons/Other'
import { OutSide } from './icons/OutSide'
import { Check } from './icons/Check'

const services = [
  {
    name: 'Chỗ đậu xe',
    icon: <Parking />,
    description: [
      //
      'Chỗ đậu xe miễn phí',
      'Chỗ đỗ xe riêng, an toàn'
    ]
  },
  {
    name: 'Tiện ích ngoài trời',
    icon: <OutSide />,
    description: [
      //
      'BBQ',
      'Sân chơi trẻ em',
      'Ban công, Sân thượng',
      'Hồ bơi',
      'Sân vườn & Cây xanh'
    ]
  },
  {
    name: 'Dịch vụ lau dọn',
    icon: <Clean />,
    description: [
      //
      'Giặt khô',
      'Giặt ủi',
      'Dọn phòng hàng ngày'
    ]
  },
  {
    name: 'Dịch vụ giải trí và gia đình',
    icon: <Tivi />,
    description: [
      //
      'Karaoke',
      'Truyền hình cáp free'
    ]
  },
  {
    name: 'Dịch vụ khác',
    icon: <Other />,
    description: [
      //
      'Xe đưa đón tận nơi',
      'Check-in, tham quan'
    ]
  }
]

export const NovaServices = () => {
  return (
    <section id="NovaServices" className="w-full mb-10 px-4 md:px-0 bg-gray-300">
      <div className="max-w-screen-sm m-auto">
        <div className="border-solid border-b-2 border-sky-500  py-2 mb-5">
          <h4 className="font-bold text-xl md:text-3xl">TIỆN ÍCH XUNG QUANH</h4>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-4">
          {services.map((service) => (
            <div key={service.name}>
              <div className="flex gap-2 items-center ">
                {service.icon}
                <h4 className="font-bold text-lg md:text-2xl">{service.name}</h4>
              </div>
              <div className="ml-9 flex flex-col gap-2 text-lg md:text-xl justify-center">
                {service.description.map((description, i) => (
                  <div key={i} className="flex gap-1 items-center">
                    <Check className="inline-block text-green-500" />
                    <h4 className="text-base md:text-md">{description}</h4>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}
