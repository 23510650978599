import React from 'react'
//import { CallButton } from '../Header/CallButton'

export const NovaPosterGift = () => {
  let linkanh = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17]


  return (
    <section className="w-full">
      <div className="max-w-screen-sm mx-auto">


        {linkanh.map((item) => (

          <img key={item} className="w-full" src={`./images/anhpdf/_${item}.png`} alt="" />
        ))}


       {/*  <CallButton /> */}
      </div>
    </section>
  )
}
