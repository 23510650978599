'use client'
import { socket } from '../../../socket_server/socket';

// import dấu mũi tên xuống để người dùng nhấp vào thêm sản phẩm 
import { ArrowDown } from '../../../icons/ArrowDown'


import { useEffect, useState } from 'react'

// những thông tin liên quan đến villa cụ thể
import { RoomItem } from './RoomItem'

import './Infor.css'

/* Xác định ban đầu có 3 sản phẩm được hiển thị */
const ITEM_PER_PAGE = 3;


export const NovaBookRoom = () => {

  /* Đây là mảng data chính được đưa vào đầu tiên */
  const [DATA_ROOMS, setDATA_ROOMS] = useState([])

  /* set lại độ dài hiển thị khi có lệnh */
  const [itemLength, setItemLength] = useState(ITEM_PER_PAGE)
  const [itemLength_Xrender, setItemLength_Xrender] = useState(ITEM_PER_PAGE)
  const [itemLength_Yrender, setItemLength_Yrender] = useState(ITEM_PER_PAGE)


  /* gán lại danh sách render sản phẩm khi độ dài của sản phẩm render có sự thay đổi */
  const [dataRender, setDataRender] = useState(DATA_ROOMS.slice(0, itemLength))

  const [arrX_villa, setarrX_villa] = useState([]);
  const [arrY_villa, setarrY_villa] = useState([]);

  const [arrX_villa_render, setarrX_villa_render] = useState([]);
  const [arrY_villa_render, setarrY_villa_render] = useState([]);



/* khi người dùng nhấp vào xem thêm sản phẩm thì đọc và set lại độ dài sản phẩm gốc */
  const handleNext = () => {
    //setItemLength(DATA_ROOMS.length)
    setItemLength_Yrender(arrY_villa.length)
  }
//handleNext_won
const handleNext_won = () => {
  //setItemLength(DATA_ROOMS.length)
  setItemLength_Xrender(arrX_villa.length)
}

  useEffect(() => {
    /* Khi độ dài sản phẩm trình chiếu thay đổi thì danh sách nó cũng sẽ được cập nhật trên màn hình */
    setarrY_villa_render(arrY_villa.slice(0, itemLength_Yrender))
  }, [itemLength_Yrender])

  useEffect(() => {
    /* Khi độ dài sản phẩm trình chiếu thay đổi thì danh sách nó cũng sẽ được cập nhật trên màn hình */
    setarrX_villa_render(arrX_villa.slice(0, itemLength_Xrender))
  }, [itemLength_Xrender])

  // useEffect(() => {
  //   /* Khi độ dài sản phẩm trình chiếu thay đổi thì danh sách nó cũng sẽ được cập nhật trên màn hình */
  //   setDataRender(DATA_ROOMS.slice(0, itemLength))
  // }, [itemLength])


/* 

/* 

DATA_ROOMS = [item0, item1, item2..  ]
 dataRender  = DATA_ROOMS.slice(0, itemLength)
 item = {
    price: "120000",
    hide: false,
    code: 'ESTELLA VILLA 2.09',
    villaName: 'ESTELLA VILLA',
    villaPath: 'estella-villa',
    rooms: 2,
    wc: 3,
    area: 300,
    beds: 4,
    people: [4, 6],
    swimmingPool: false,
    giftParty: false,
    description:
      'Estella Villa là một biểu tượng của sự sang trọng và tiện nghi, đặt trong khuôn viên tuyệt đẹp của một khu nghỉ dưỡng xa hoa. Villa này là một nơi lý tưởng để trốn khỏi cuộc sống đô thị ồn ào và tận hưởng không gian riêng tư và yên bình.',
    images: {
      length: 26,
      path: 'estella-villa'
    }


*/


// nơi import thông tin data mảng để render ra begin
  useEffect(() => {
  socket.emit("allvilla", {  }); // gửi về máy chủ để nhận danh sách phòng phục vụ mục đích render
  socket.on('allvilla', msg => { // sự kiện server gửi dữ liệu đến
   console.log("Đã nhận danh sách phòng từ máy chủ");
   ////////////////////////// test code begin
  
  const X = [];
  const Y = [];
  
  // cắt mảng to thành 2 phần : Tropicana và wonderlan
  msg.allvilla.forEach(item => {
    if (item.MaCan.includes('B') || item.MaCan.includes('C') || item.MaCan.includes('D')) {
      X.push(item);
    } else {
      Y.push(item);
    }
  });
  setarrX_villa(X);
  setarrY_villa(Y);
  console.log('Mảng X:', X);
  console.log('Mảng Y:', Y);
  
  setarrX_villa_render(X.slice(0, itemLength_Xrender))
  setarrY_villa_render(Y.slice(0, itemLength_Yrender))

   ////////////////////////// test code end
   console.log(msg.allvilla) ;

  setDataRender(msg.allvilla.slice(0, itemLength))
  setDATA_ROOMS(msg.allvilla);
 
  });
  
}, [])
// nơi import thông tin data mảng để render ra end



//J:\data\website\web_nova\client_web\src\components\Nova\BookRoom\index.jsx


if (1 == 1) {

  return (
    <div>
    <section className="w-full mb-6 bg-gray-300"
    id="VILLA VIEW"
    >
      <div className="max-w-screen-lg m-auto mt-12 md:mt-8">
      <h2 className="font-bold text-xl md:text-3xl text-center">
      ĐẶT <span className="red-text">VILLA</span></h2>
        <h2 className="font-bold text-lg md:text-3xl text-center">Tại TROPICANA</h2>
      </div>
      <div className="max-w-screen-sm m-auto mt-4 ">
      {arrY_villa_render.map((item, index) => {
          return <RoomItem key={index} item={item} />
        })}
        {DATA_ROOMS.length !== arrY_villa_render.length && (
          <div
            className="mt-4 text-center flex gap-0 flex-col justify-center items-center hover:text-blue-500 hover:cursor-pointer"
            onClick={() => handleNext()}
          >
            Xem thêm villa trống <ArrowDown className="w-9 h-auto" />
          </div>
        )}
      </div>
    </section>
{/*  */}

<section className="w-full mb-6 bg-gray-300"
    id="VILLA VIEW XX"
    >
      <div className="max-w-screen-lg m-auto mt-12 md:mt-8">
      <h2 className="font-bold text-xl md:text-3xl text-center">
      ĐẶT <span className="red-text">VILLA</span></h2>
        <h2 className="font-bold text-lg md:text-3xl text-center">Tại WONDERLAND</h2>
      </div>
      <div className="max-w-screen-sm m-auto mt-4 ">
      {arrX_villa_render.map((item, index) => {
          return <RoomItem key={index} item={item} />
        })}
        {DATA_ROOMS.length !== arrX_villa_render.length && (
          <div
            className="mt-4 text-center flex gap-0 flex-col justify-center items-center hover:text-blue-500 hover:cursor-pointer"
            onClick={() => handleNext_won()}
          >
            Xem thêm villa trống <ArrowDown className="w-9 h-auto" />
          </div>
        )}
      </div>
    </section>


    </div>
  )

        }






}


