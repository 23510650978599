import React from 'react'

// nhiệm vụ của thẻ link là khi người dùng nhấp vào thẻ, nó sẽ đổi URL web
import { Link } from 'react-router-dom'

// Tất cả những infor villa bằng chữ viết
import { RoomInfo } from './RoomInfo'

// biểu tượng của nút chi tiết
import { BookNow } from '../../../icons/BookNow'


/* 
 item = {
    price: "120000",
    hide: false,
    code: 'ESTELLA VILLA 2.09',
    villaName: 'ESTELLA VILLA',
    villaPath: 'estella-villa',
    rooms: 2,
    wc: 3,
    area: 300,
    beds: 4,
    people: [4, 6],
    swimmingPool: false,
    giftParty: false,
    description:
      'Estella Villa là một biểu tượng của sự sang trọng và tiện nghi, đặt trong khuôn viên tuyệt đẹp của một khu nghỉ dưỡng xa hoa. Villa này là một nơi lý tưởng để trốn khỏi cuộc sống đô thị ồn ào và tận hưởng không gian riêng tư và yên bình.',
    images: {
      length: 26,
      path: 'estella-villa'
    }


*/


export const RoomItem = ({ item }) => {

  const handleImageLoad = () => {

    console.log("Load ảnh");

  }
  let css_chitiet = { top: "40px" };


  return (
    <div>
      {/* to={`/rooms/${item.villaPath}`} */}
      <div

        className=" relative flex flex-col md:flex-row md:justify-between md:items-center border border-solid border-gray-200 rounded-md p-3 mt-4 hover:border-green-500 transition-all duration-300 hover:shadow"
      >
        <div className="flex md:flex-row md:items-start">
          {/* khu vực này là lấy 1 ảnh đại diện cho sản phẩm */}
          <div className="w-24 h-24 md:w-36 md:h-36 bg-gray-300 rounded shadow">
          {/* src={`/images/rooms/${item.images?.path}/_1.jpg`} */} 
            <div className='relative'>
              <img
                src={`/images/rooms/${item.images?.path}/${item.galleries[0].original}`}
                alt=""
                className="w-24 h-24 md:w-36 md:h-36 object-cover rounded"
                onLoad={handleImageLoad}  // sự kiện này nói lên là đã load ảnh xong
              />

              <div className="css_text_midle">{item.adressshort}</div>
            </div>

          </div>


          {/* bên cạnh tấm ảnh đó ta render bên phải những thứ liên quan sản phẩm */}
          <RoomInfo item={item} />


          <div className="flex flex-col md:flex-row md:items-center absolute right-4 bottom-4 bookingroom"
         
          >


            <div style={{display : 'flex' , flexDirection : 'column', gap: '5px', textAlign: 'center'}}>
              <Link
                to={`/rooms/${item.villaPath}`}
                className="bg-blue-500 text-white px-2 py-1 md:py-2 md:px-4 rounded-md transition-all duration-200 hover:shadow-lg hover:bg-orange-500"
              >
                <BookNow className="inline-block" /> Chi tiết
              </Link>



              {/* <Link
                to={`/rooms/${item.villaPath}`}
                className="bg-blue-500 text-white px-2 py-1 md:py-2 md:px-4 rounded-md transition-all duration-200 hover:shadow-lg hover:bg-orange-500"
              >
                <BookNow className="inline-block" /> Booking Villa
              </Link> */}


            </div>

          </div>
        </div>

        {/*       <Link
        to={`/rooms/${item.villaPath}`}
        className="bg-blue-500 text-white px-2 py-1 md:py-2 md:px-4 rounded-md transition-all duration-200 hover:shadow-lg hover:bg-orange-500"
      >
        <BookNow className="inline-block" /> Chi tiết
      </Link> */}


      </div>





    </div>
  )
}
