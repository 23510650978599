import React from 'react'
import { InfoSection } from './InfoSection'

const informations = [
  {
    title: 'Hồ bơi nước mặn Ocean Pool - 500m2',
    description: `Ocean Pool ở tại dự án Novaland Hồ Tràm do tập đoàn Novaland triển khai. Là hồ bơi
      nước mặn quy mô lên đến 5000m2, sẽ mang đến cho quý khách không gian trải nghiệm
      đầy mới mẻ và chân thật nhất.`,
    image: '/images/informations/1.jpg',
    bgColor: 'bg-blue-100'
  },
  {
    title: 'Dịch vụ đẳng cấp',
    description: ` Bên cạnh hồ bơi còn có nhà hàng The Shark Hồ Tràm, công viên Tropicana Park, Tropicana
    Beach Club, Organic Farm,... Nơi mà du khách thư giãn, tận hưởng những thức ăn đồ uống
      đặc trưng nơi đây.`,
    image: '/images/informations/2.jpg',
    bgColor: 'bg-green-100'
  },
  {
    title: 'Nằm kề Tropicana Park',
    description: `Tropicana Park trực thuộc NovaWorld Hồ Tràm, là dự án công viên giải trí lấy cảm
      hứng từ văn hóa tộc người Polynesian ở Thái Bình Dương. Ấn tượng đầu tiên về
      Tropicana Park là bối cảnh các trò chơi có bối cảnh mang đậm sắc màu đảo Hawaii,
      kèm theo đó là hình ảnh Tiki - biểu tượng đặc trưng của người dân đảo.`,
    image: '/images/informations/3.jpg',
    bgColor: 'bg-yellow-100'
  },
  {
    title: 'Minera Hot Springs Binh Chau',
    description: `Tháng 06/2021 Novaland chính thức "thâu tóm" mua lại Suối Nước Nóng Bình Châu
    (Minera Hot Spring Binh Chau), là suối khoáng duy nhất tại miền nam, lớn nhất
    Đông Nam Á.`,
    image: '/images/informations/4.jpg',
    bgColor: 'bg-cyan-100'
  }
]

export const NovaInformation = () => {
  return (
    <section id="NovaInformation" className="my-12">
      <div className="max-w-screen-lg m-auto">
        {informations.map((info, index) => (
          <InfoSection
            key={index}
            title={info.title}
            description={info.description}
            image={info.image}
            isReverse={index % 2 === 0}
            bgColor={info.bgColor}
          />
        ))}
      </div>
    </section>
  )
}
