export const OutSide = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 98.098 100"
      enableBackground="new 0 0 98.098 100"
      xmlSpace="preserve"
      preserveAspectRatio="none"
      width="30px"
      height="30px"
      className=""
      fill="rgba(0, 102, 171, 1)"
      {...props}
    >
      <switch>
        <foreignObject
          requiredExtensions="http://ns.adobe.com/AdobeIllustrator/10.0/"
          x={0}
          y={0}
          width={1}
          height={1}
        />
        <g>
          <path d="M94.519,90.98H77.273c-0.87,0-1.58-0.584-1.58-1.299v-4.615c0-0.715-0.713-1.299-1.583-1.299H53.108    c-0.869,0-1.581-0.584-1.581-1.3V76.05c0-0.716-0.712-1.3-1.583-1.3H6.974c-0.871,0-1.583,0.584-1.583,1.3V98.7    c0,0.716,0.712,1.3,1.583,1.3h21.002c0.871,0,2.293,0,3.164,0h17.244c0.869,0,1.934,0,2.362,0s1.493,0,2.362,0H74.11    c0.87,0,2.293,0,3.163,0h17.245c0.87,0,1.583-0.584,1.583-1.3v-6.419C96.102,91.564,95.389,90.98,94.519,90.98z" />
          <path d="M53.291,24.801c0.075,0,0.146-0.022,0.221-0.024c0.074,0.006,0.146,0.024,0.222,0.024h27.22    c0.197,0,0.389-0.022,0.578-0.061c3.104-0.269,5.584-2.647,6.012-5.703c-4.866-0.916-8.564-5.145-8.663-10.248    c-1.18-1.835-3.21-3.066-5.559-3.066c-2.982,0-5.479,1.967-6.333,4.669c-0.956-0.529-2.038-0.854-3.204-0.854    c-2.659,0-4.936,1.567-6.008,3.817h-4.167c-0.055,0-0.105,0.012-0.158,0.014c-0.054-0.002-0.104-0.014-0.159-0.014    c-3.162,0-5.723,2.56-5.723,5.721C47.569,22.237,50.129,24.801,53.291,24.801z" />
          <circle cx="89.512" cy="8.583" r="8.583" />
          <path d="M0.162,22.485l0.002,0.004c0.042,0.13,0.09,0.25,0.153,0.368l16.218,38.868h5.68l-0.006-0.013h2.289    l-10.056,9.629c-0.735,0.704-0.503,1.288,0.517,1.298l3.497,0.035c1.021,0.012,2.456-0.554,3.196-1.254l8.708-8.22    c0.061-0.062,0.089-0.144,0.142-0.208c0.058,0.086,0.09,0.182,0.161,0.259l7.542,8.015c0.698,0.743,2.104,1.346,3.121,1.342    l3.532-0.019c1.02-0.006,1.278-0.612,0.574-1.347l-9.108-9.529h9.766c0.026,0,0.052,0.012,0.08,0.012    c1.408,0,2.552-1.156,2.552-2.586c0-1.426-1.145-2.588-2.552-2.588c-0.026,0-0.049,0.016-0.072,0.018H20.051L4.958,20.626    l-0.017,0.006c-0.404-0.901-1.302-1.536-2.355-1.536C1.159,19.096,0,20.255,0,21.682C0,21.966,0.077,22.229,0.162,22.485    L0.162,22.485z" />
          <path d="M52.776,53.736c0.61,2.074,2.787,3.267,4.86,2.658c1.997-0.59,3.154-2.621,2.705-4.619h0.005l-0.016-0.053    c-0.016-0.064-0.018-0.131-0.036-0.195c-0.015-0.053-0.043-0.098-0.059-0.146l-5.56-19.644l-8.359,2.447l-9.318,9.75h11.312h0.736    l0.021-0.022l0.008,0.022h0.894l2.741,9.656L52.776,53.736z" />
          <path d="M23.573,54.964H47.34l1.794,1.913l16.885,16.196c1.651,1.647,4.379,1.591,6.084-0.122    c1.636-1.645,1.727-4.211,0.273-5.871l-0.175-0.214l-11.365-10.86c-0.68,0.924-1.634,1.652-2.808,1.998    c-0.5,0.148-1.015,0.223-1.532,0.223c-1.959,0-4.729-0.801-5.229-3.229l-0.167-0.64l-0.925-3.377l-1.397-5.396l-0.016-0.059    h-19.04l-4.875-10.403l6.219,0.09l4.526-4.979L22.826,19.407c-0.588-3.676-3.748-6.494-7.59-6.494c-4.26,0-7.715,3.45-7.715,7.713    c0,4.26,3.455,7.715,7.715,7.715c1.91,0,3.638-0.72,4.986-1.872l3.469,2.942l-11.053,0.434c-1.021,0.039-1.528,0.834-1.134,1.774    l9.458,22.488c0.15,0.356,0.312,0.793,0.723,0.793" />
        </g>
      </switch>
    </svg>
  )
}
