import { Col, DatePicker as AntDatePicker, Form, Input, Row, InputNumber, message, Button } from 'antd'
import dayjs from 'dayjs'
import { Send } from './Send'
import { useState } from "react";
import React, { useEffect } from 'react';
import { socket } from '../socket_server/socket';
import './config.css';
//J:\data\website\web_nova\client_web\src\configimg\input.js
//import UploadImages from './UploadImages'
import { NovaMemories } from './NovaMemories'
//J:\data\website\web_nova\save_anh_tongquat\src\socket_server\input.js

//let Successfulorder = "Đã đặt hàng thành công! Chúng tôi sẽ liên hệ sớm";
//let Successfulorder = "Đăng ký ngay";
let textColor = 'black';
const DatePicker = AntDatePicker
// eslint-disable-next-line arrow-body-style
const disabledDate = (current) => {
  // Can not select days before today and today
  return current && current < dayjs().endOf('day')
}

let dataRender = ['Nhóm Thuê', 'Nhóm Bán'];
let configvilla = ['Thêm mới', 'Chỉnh Sửa'];

let configvilla_thue = ['Thêm mới', 'Chỉnh Sửa', 'sửa giá', 'Ẩn hiện Villa'];

let anhien_vill = ['Ẩn Villa', 'hiện villa'];

export const RegisterForm = ({ className }) => {

  const [Successfulorder, setSuccessfulorder] = useState("Không được nhấp vào đây");
  const [Renderupload, setRenderupload] = useState(false);
  const [Linkurl, setLinkurl] = useState("");
  const [Urlinput, setUrlinput] = useState("");
  const [Choosegroup, setChoosegroup] = useState("");
  const [loaisanpham, setloaisanpham] = useState([]);
  const [data_sql, setdata_sql] = useState([]);
  const [ChooseSanpham, setChooseSanpham] = useState({});
  const [ChiTietNha, setChiTietNha] = useState([]);
  const [Successfulordersp, setSuccessfulordersp] = useState("Hình ảnh");

  const [Successfulorder_savegia, setSuccessfulorder_savegia] = useState("Lưu Giá");

  const [Successfulordernew, setSuccessfulordernew] = useState("Hình ảnh");

  //const [anhien_vill, setanhien_vill] = useState("Hình ảnh");

  //neworedit   
  const [Neworedit, setNeworedit] = useState("");
  const [Typesanpham, setTypesanpham] = useState("Loại Sản Phẩm");
  const [ListID, setListID] = useState([]);
  const [newID, setnewID] = useState(null);
  const [suaten, setsuaten] = useState(false);
  const [isReadOnly, setIsReadOnly] = useState(false);

  const [arr_suagia, setarr_suagia] = useState([]);
  const [obj_suagia, setobj_suagia] = useState({});
  const [suagia_render, setsuagia_render] = useState(false);

  useEffect(() => {

    socket.on("suagia_villa", msg => { // sự kiện server gửi dữ liệu đến

      setSuccessfulorder_savegia('Đã lưu giá');
      socket.emit("xin sửa giá", { new: '' });
      setTimeout(() => {

        setSuccessfulorder_savegia("Lưu Giá");

      }, 2000)


    });
    /////////////////////////

    socket.on("API ẩn hiện villa", msg => { // sự kiện server gửi dữ liệu đến

    if (msg.kq == '1'){
    // alert("Đã gửi lệnh ẩn hiện về máy chủ thành công");
    console.log("Đã gửi lệnh ẩn hiện về máy chủ thành công");

    socket.emit("xin ẩn hiện villa", { new: '' });
    console.log('Đã nhấp vào xin ẩn hiện villa')

    setTypesanpham("Loại Sản Phẩm");
    document.getElementById('dropbtn_sanpham').textContent = "Loại Sản Phẩm";
    };



    });

    //////////////////////
    socket.on('allhotel', msg => { // sự kiện server gửi dữ liệu đến
      console.log("HEO--->>>>>>>>");
      console.log(msg.allhotel);

      const data_x = msg.allhotel;
      setdata_sql(data_x);
      const villaNames = data_x.map(item => item.villaName);
      setloaisanpham(villaNames); // lưu lại một mảng các tên ID để render ra button

      const arr_id = data_x.map(item => item.MaCan);
      setListID(arr_id);


      //console.log(villaNames) ;
    });


    socket.on('saveanh', msg => { // máy chủ trả lời gửi danh sách hình ảnh qua
      // alert(msg.data)
      console.log("msg----->:", msg.extimg);

      setLinkurl(msg.extimg); // lưu vào biến này để truyền tải vào component khác
      setRenderupload(true); // xác nhận render component khác

    });


    socket.on("xin sửa giá", msg => { // máy chủ trả lời gửi danh sách hình ảnh qua
      // alert(msg.data)

      const parts_sp = msg.kq.split(';');
      const arr_sp_gia = [];
      const mangsuagia = [];
      parts_sp.forEach((part, index) => {


        let tt_villa = part.split(',');

        arr_sp_gia.push(tt_villa[0] + ' ' + tt_villa[2]);

        let tientach = tt_villa[1].split('-');
        // console.log("xin sửa giá : ", objsuagia);
        let thu_hai_nam = tientach[0].split('G')[1];
        //console.log("xin sửa giá : ", thu_hai_nam);
        let thu_sau_bay = tientach[6].split('G')[1];;
        let thu_ngayle = tientach[7].split('G')[1];;
        let objsuagia =
        {
          tencan: tt_villa[0] + ' ' + tt_villa[2],
          gia_hai_nam: thu_hai_nam,
          gia_sau_bay: thu_sau_bay,
          gia_ngayle: thu_ngayle,
          macan: tt_villa[2],
        }

          ;
        //console.log("xin sửa giá : ", mangsuagia);
        mangsuagia.push(objsuagia);

      });

      // console.log("xin sửa giá : ", mangsuagia);
      setarr_suagia(mangsuagia);

      setloaisanpham(arr_sp_gia);

    });
    ///////////////////////////////////



    socket.on("xin ẩn hiện villa", msg => { // máy chủ trả lời gửi danh sách hình ảnh qua
      // alert(msg.data)

      const parts_sp = msg.kq.split(';');
      const arr_sp_gia = [];
      const mangsuagia = [];
      parts_sp.forEach((part, index) => {


        let tt_villa = part.split(',');

        arr_sp_gia.push(tt_villa[0] + ' ' + tt_villa[2]);


        let objsuagia =
        {
          tencan: tt_villa[0] + ' ' + tt_villa[2],
          an_hien: tt_villa[1],
          macan: tt_villa[2],
        }

          ;
        console.log("xin ẩn hiện villa : ", objsuagia);
        mangsuagia.push(objsuagia);

      });

      // console.log("xin sửa giá : ", mangsuagia);
      setarr_suagia(mangsuagia);

      setloaisanpham(arr_sp_gia);

    });



    //////////////////////////////////
    ///confighotel
    socket.on('confighotel', msg => { // máy chủ trả lời gửi danh sách hình ảnh qua
      // alert(msg.data)
      console.log("confighotel hotle mới thành công:");
      message.success('Đã Lưu Thông Tin về Máy Chủ Thành Công');
      setSuccessfulordersp("Đã Thêm Thông Tin mới về máy Chủ Thành công");
      textColor = 'green';
      setTimeout(function () { setSuccessfulordersp("Lưu Về Máy Chủ"); textColor = 'black'; selectOption("Nhóm Bán"); }, 2000);

    });


    //////////
    socket.on('newhotel', msg => { // máy chủ trả lời gửi danh sách hình ảnh qua
      // alert(msg.data)
      console.log("Đã gửi hotle mới thành công:");
      message.success('Đã Lưu Thông Tin về Máy Chủ Thành Công');
      setSuccessfulordernew("Đã Thêm Thông Tin mới về máy Chủ Thành công");
      textColor = 'green';
      setTimeout(function () { setSuccessfulordernew("Lưu Về Máy Chủ"); textColor = 'black'; selectOption("Nhóm Bán"); setNeworedit('Thao Tác'); setRenderupload(false); }, 2000);

    });

    //xoahotel
    socket.on('xoahotel', msg => { // máy chủ trả lời gửi danh sách hình ảnh qua
      // alert(msg.data)
      console.log("xóa sản phẩm hotel thành công");
      //setChoosegroup("");
      setNeworedit('Thao Tác');
      setTypesanpham('Loại Sản Phẩm');
      setRenderupload(false)
      //setTimeout(function () { setSuccessfulordernew("Lưu Về Máy Chủ"); textColor = 'black'; selectOption("Nhóm Bán"); }, 2000);

    });



    return () => { // trả về sự kiện để có thể làm sạch lịch sử của nó
      //socket.off('Sendreceive', onConnect);

    };


  }, []);


  const createID = () => {
    let newElement;
    do {
      newElement = Math.round(Math.random() * 100) / 10; // Tạo ra một số ngẫu nhiên từ 0 đến 10 với một chữ số thập phân
      if (Number.isInteger(newElement)) {
        newElement = newElement.toFixed(1);
      }

    } while (ListID.includes(newElement)); // Kiểm tra xem số ngẫu nhiên mới đã tồn tại trong dsid chưa
    console.log('ID------->', newElement);
    setnewID(newElement);
  };



  const onFinish_suagia = (values) => {


    if (Successfulorder_savegia === "Lưu Giá") {


      console.log("Tên sản phẩm: " + values.fullName);
      console.log("CN-->5: " + values.tenphu);
      console.log("6-7: " + values.dientich);
      console.log("ngayle: " + values.hientrang);
      console.log('macan: ', obj_suagia.macan)

      let data_gia = "1G" + values.tenphu + "-2G" + values.tenphu + "-3G" + values.tenphu + "-4G" + values.tenphu + "-5G" + values.tenphu + "-6G" + values.dientich + "-7G" + values.dientich + "-LG" + values.hientrang;
      console.log('data_gia: ', data_gia);

      socket.emit("suagia_villa", { macan: obj_suagia.macan, datagia: data_gia });
      textColor = 'green';

      setSuccessfulorder_savegia('Đang lưu giá');



    }

  }



  const onFinish_sanpham = (values) => {

    if (Successfulordersp === "Hình ảnh") {

      if (ChooseSanpham.villaName == values.fullName) {
        if (values.fullName.includes("CẦN BÁN") == true) {

          let originalString = values.fullName;
          let villpath = originalString.toLowerCase().replace(/ /g, "-");
          let urlgui = "./duan/images/rooms/" + villpath;
          socket.emit("saveanh", { hai: urlgui });
          setUrlinput(urlgui);

          console.log(urlgui);
          setSuccessfulordersp("Lưu Về Máy Chủ");
        } else {
          setSuccessfulordersp("LỖI : Tên sản phẩm phải có chữ --> CẦN BÁN");
          setTimeout(() => {
            setSuccessfulordersp("Hình ảnh");

          }, 3000);

        };

        setIsReadOnly(true); // cấm chỉnh sủa tên sản phẩm 
      } else {
        console.log('Bạn đã sửa tên không cho GEN hình ảnh');
        setsuaten(true);
        setSuccessfulordersp("Không Được Phép Thao Tác Hình Ảnh");
        setTimeout(() => {
          setSuccessfulordersp("Lưu Về Máy Chủ");

        }, 3000);


      }

      return;
    };


    if (Successfulordersp === "Lưu Về Máy Chủ") {
      //setSuccessfulorder("Đang gửi...");
      console.log("sự kiện lưu về máy chủ: ", values);
      //let xy = values.fullName;
      //console.log(Form.getFieldsValue().fullName);

      console.log("Tên sản phẩm" + values.fullName);
      console.log("List-Thêm Tên" + values.tenphu);
      console.log("List-Diện Tích" + values.dientich);
      console.log("List-Hiện Trạng" + values.hientrang);
      console.log("List-Giá Bán" + values.giaban);
      let fullName = values.fullName.replace(/,/g, '@@');
      let tenphu = values.tenphu.replace(/,/g, '@@');
      let dientich = values.dientich.replace(/,/g, '@@');
      let hientrang = values.hientrang.replace(/,/g, '@@');
      let giaban = values.giaban.replace(/,/g, '@@');
      let stt = values.detailstt;

      console.log("detail-Giá Bán" + values.detailgiaban);
      console.log("detail-Số Tiền Thanh Toán" + values.detailtienthanhtoan);
      console.log("detail-Loại Hình" + values.detailloaihinh);
      console.log("detail-Diện Tích Đất" + values.detaildientich);
      console.log("detail-Xây Dựng" + values.detailxaydung);

      console.log("detail-Thời Gian Nhận Nhà" + values.detailtimenha);
      console.log("detail-Tiêu Chuẩn Bàn Giao" + values.detailbangiao);

      console.log("detail-Hướng Nhà" + values.detailhuongnha);
      console.log("detail-Pháp Lý" + values.detailphaply);
      console.log("detail-Vay Ngân Hàng" + values.detailvaynganhang);
      console.log("detail-Ngân Hàng Cho Bên Mua Vay" + values.detailbenmuavay);
      console.log("detail-Điện Thoại" + values.detaildienthoai);

      let des = values.detailgiaban + "@T@" + values.detailtienthanhtoan + "@T@" + values.detailloaihinh + "@T@" +
        values.detaildientich + "@T@" + values.detailxaydung + "@T@" + values.detailbangiao + "@T@" + values.detailtimenha + "@T@" +
        values.detailhuongnha + "@T@" + values.detailphaply + "@T@" + values.detailvaynganhang + "@T@" + values.detailbenmuavay + "@T@" +
        values.detaildienthoai;
      des = des.replace(/,/g, '@@');

      //sql: ten_villa --> values.fullName
      //sql: pn  --> values.tenphu
      //sql: met ---> values.dientich
      //sql: giuong --> values.hientrang
      // sql: swim --> values.giaban
      // sql: des---> des.

      //socket.emit("saveanh",   {hai : values.fullName} );//gửi đường link về máy chủ để nhận danh sách hình ảnh
      //setUrlinput(values.fullName); // // lưu lại giá trị url này để truyền vào một Component khác.
      //     let sdt = "0933333325";
      // let ten = "Nguyễn Đức Hải";
      // let ngay_batdau = "1/7/2023";
      // let ngay_ketthuc = "10/7/2023";
      // let nguoi_lon = "5";
      // let tre_em = "3"
      // let tin_nhan = "Đặt phòng tốt cho tôi";
      // let get_url = "https://tuongdoirong.com/api/node.php?f=inst&database=happystayresort&mytable=khachbook&cot=sdt,ten,date_start,date_end,nguoi_lon,tre_em,tin_nhan&gt='"+sdt+"','"+ten+"','"+ngay_batdau+"','"+ngay_ketthuc+"','"+nguoi_lon+"','"+tre_em+"','"+tin_nhan+"'";
      //console.log("/////////////////////")
      //console.log(values.fullName);

      // cập nhật căn mới
      // https://tuongdoirong.com/api/node.php?f=inst&database=mindx&mytable=banggiathue&cot=MaCan,ten_villa,pn,met,giuong,swim,des,loadinfor,type&gt='1','2','3','4','5','6','7','1','hotel'
      let thayten = [];
      if (ChooseSanpham.villaName != values.fullName) {
        thayten = [ChooseSanpham.villaName, values.fullName];

      };



      var thongtin_socket = fullName +
        `', pn='` + tenphu + `', met='` + dientich + `', giuong='` + hientrang + `', swim='` + giaban + `', des='` + des + `', sltc='` + stt + `' WHERE MaCan='` + ChooseSanpham.MaCan + `'`;
      socket.emit("confighotel", { config: thongtin_socket, doiten: thayten });


      // if (1 == 0) {
      //   let urlget = `https://tuongdoirong.com/api/node.php?f=upd&database=happystayresort&mytable=banggiathue&up_gt=ten_villa='` + fullName +
      //     `', pn='` + tenphu + `', met='` + dientich + `', giuong='` + hientrang + `', swim='` + giaban + `', des='` + des + `' WHERE MaCan='` + ChooseSanpham.MaCan + `'`;

      //   try {
      //     fetch(urlget)
      //       .then((response) => response.json())
      //       .then((data) => {
      //         if (data.status === 'success') {
      //           message.success('Liên hệ thành công!, Xin hãy chờ phản hồi sớm nhất từ chúng tôi');
      //           console.log("Đã đặt hàng thành công")
      //         }
      //       })
      //       .catch((error) => {
      //         message.error('Đã có lỗi xảy ra, vui lòng thử lại sau')
      //       })
      //       .finally(() => {
      //         console.log('Done')
      //         message.success('Đã Lưu Thông Tin về Máy Chủ Thành Công');
      //         //Successfulorder = "Đã đặt hàng thành công! Chúng tôi sẽ liên hệ sớm";
      //         setSuccessfulordersp("Đã đặt hàng thành công! Chúng tôi sẽ liên hệ sớm");
      //         textColor = 'green';
      //         setTimeout(function () { setSuccessfulordersp("Lưu Về Máy Chủ"); textColor = 'black'; }, 10000)
      //       })
      //   } catch (error) {
      //     message.error('Đã có lỗi xảy ra, vui lòng thử lại sau')
      //   }
      // }



    }

  }




  const onFinish = (values) => {
    if (Successfulorder === "Không được nhấp vào đây") {
      //setSuccessfulorder("Đang gửi...");

      socket.emit("saveanh", { hai: values.fullName });//gửi đường link về máy chủ để nhận danh sách hình ảnh
      setUrlinput(values.fullName); // // lưu lại giá trị url này để truyền vào một Component khác.

      console.log("/////////////////////")
      console.log(values.fullName);


    }

  }


  const onFinish_them = (values) => {
    if (Successfulordernew === "Hình ảnh") {
      if (values.fullName.includes("CẦN BÁN") == true) {
        let originalString = values.fullName;
        let villpath = originalString.toLowerCase().replace(/ /g, "-");
        let urlgui = "./duan/images/rooms/" + villpath;
        socket.emit("saveanh", { hai: urlgui });
        setUrlinput(urlgui);
        console.log(urlgui);
        setSuccessfulordernew("Lưu Về Máy Chủ");
      } else {
        setSuccessfulordernew("LỖI : Tên sản phẩm phải có chữ --> CẦN BÁN");
        setTimeout(() => {
          setSuccessfulordernew("Hình ảnh");

        }, 3000);

      };


      return;
    };

    if (Successfulordernew === "Lưu Về Máy Chủ") {
      console.log('Lưu mới về máy chủ')
      let fullName = values.fullName.replace(/,/g, '@@');
      let tenphu = values.tenphu.replace(/,/g, '@@');
      let dientich = values.dientich.replace(/,/g, '@@');
      let hientrang = values.hientrang.replace(/,/g, '@@');
      let giaban = values.giaban.replace(/,/g, '@@');
      let des = values.detailgiaban + "@T@" + values.detailtienthanhtoan + "@T@" + values.detailloaihinh + "@T@" +
        values.detaildientich + "@T@" + values.detailxaydung + "@T@" + values.detailbangiao + "@T@" + values.detailtimenha + "@T@" +
        values.detailhuongnha + "@T@" + values.detailphaply + "@T@" + values.detailvaynganhang + "@T@" + values.detailbenmuavay + "@T@" +
        values.detaildienthoai;
      des = des.replace(/,/g, '@@');
      let stt = values.detailstt;

      var thongtin_socket = newID.toString() + `','` + fullName + `','` + tenphu + `','` + dientich + `','` + hientrang + `','` + giaban + `','` + des + `','` + stt + `','1','hotel'`;
      socket.emit("newhotel", { new: thongtin_socket });


      // if (1 == 0) {

      //   let urlget = `https://tuongdoirong.com/api/node.php?f=inst&database=happystayresort&mytable=banggiathue&cot=MaCan,ten_villa,pn,met,giuong,swim,des,loadinfor,type&gt='`+
      //   newID.toString() + `','` + fullName + `','` + tenphu + `','` + dientich + `','` + hientrang + `','` + giaban + `','` + des + `','1','hotel'`;

      //   try {
      //     fetch(urlget)
      //       .then((response) => response.json())
      //       .then((data) => {
      //         if (data.status === 'success') {
      //           message.success('Liên hệ thành công!, Xin hãy chờ phản hồi sớm nhất từ chúng tôi');
      //           console.log("Đã đặt hàng thành công")
      //         }
      //       })
      //       .catch((error) => {
      //         message.error('Đã có lỗi xảy ra, vui lòng thử lại sau')
      //       })
      //       .finally(() => {
      //         console.log('Done')
      //         message.success('Đã Lưu Thông Tin về Máy Chủ Thành Công');
      //         //Successfulorder = "Đã đặt hàng thành công! Chúng tôi sẽ liên hệ sớm";
      //         setSuccessfulordernew("Đã Thêm Thông Tin mới về máy Chủ Thành công");
      //         textColor = 'green';
      //         setTimeout(function () { setSuccessfulordernew("Lưu Về Máy Chủ"); textColor = 'black'; }, 10000)
      //       })
      //   } catch (error) {
      //     message.error('Đã có lỗi xảy ra, vui lòng thử lại sau')
      //   }
      // }



    };

  }



  function selectOption(option) {
    console.log('Đã nhấp vào nhóm');
    document.getElementById('dropbtn').textContent = option;
    setChoosegroup(option);
    setTypesanpham("Loại Sản Phẩm");//mặc định lại form chỉnh sửa nhà bán

    if (option == 'Nhóm Bán') {
      socket.emit("allhotel", {});
      setNeworedit('Thao Tác');
      
      setTimeout(() => {
        document.getElementById('dropbtn_config').textContent = 'Thao Tác';

      }, 500);
    };

    if (option == 'Nhóm Thuê') {
      console.log('thue --->')

    };


  }


  function selectOption_config(option) {//thao tác : chỉnh sửa hoặc thêm mới
    document.getElementById('dropbtn_config').textContent = option;
    console.log('chỉnh sửa, thêm mới..', option);

    if (option == 'sửa giá') {
      //console.log("111");
      // gọi về máy chủ xin danh sách giá
      socket.emit("xin sửa giá", { new: '' });

    };

    if (option == 'Ẩn hiện Villa') {

      socket.emit("xin ẩn hiện villa", { new: '' });
      console.log('Đã nhấp vào xin ẩn hiện villa')

    };


    if (document.getElementById('dropbtn').textContent == "Nhóm Bán") {
      //selectOption("Nhóm Bán");
     setTypesanpham("Loại Sản Phẩm");
     socket.emit("allhotel", {});
    };//refesh
    if (document.getElementById('dropbtn').textContent == "Nhóm Thuê") {
      selectOption('Nhóm Thuê');
    };//refesh


    setRenderupload(false);// mặc định lại khu vực hình ảnh
    setIsReadOnly(false);// cho phép chỉnh tên lại

    setNeworedit(option);
    setTypesanpham("Loại Sản Phẩm");
    createID();


  }

  function selectOption_anhien(option) {

    document.getElementById('dropbtn_anhien').textContent = option;
    console.log('Chọn ẩn hiện villa : ' + option);
    let sp_dachon = document.getElementById('dropbtn_sanpham').textContent;
    let result_gia = arr_suagia.find(item => item.tencan === sp_dachon);
    console.log(result_gia);
    if (option == 'hiện villa'){
      //https://tuongdoirong.com/api/node.php?f=upd&database=happystayresort&mytable=banggiathue&up_gt=loadinfor='1' WHERE MaCan='2.09'
      console.log('call api hiện villa');
      socket.emit("API ẩn hiện villa", { an_hien: 1, macan : result_gia.macan });
    }
    if (option == 'Ẩn Villa'){
      console.log('call api ẩn villa');
      socket.emit("API ẩn hiện villa", { an_hien: 0, macan : result_gia.macan });
    }


  };


  //Typesanpham != Loại Sản Phẩm
  function selectOption_sanpham(option) {
    document.getElementById('dropbtn_sanpham').textContent = option;

    setRenderupload(false);// mặc định lại khu vực hình ảnh
    setIsReadOnly(false);// cho phép chỉnh tên lại

    console.log('Đã chọn sản phẩm ok: ' + option);

    if (Choosegroup == 'Nhóm Thuê' && Neworedit == 'Ẩn hiện Villa' && option != "Loại Sản Phẩm") {
      let result_gia = arr_suagia.find(item => item.tencan === option);
      console.log('sản phẩm chọn ẩn hiện villa', result_gia.an_hien);
      if (result_gia.an_hien == '1'){

        setTimeout(() => {
          document.getElementById('dropbtn_anhien').textContent = 'hiện villa';
        },1000)
       // 

      }else{

        setTimeout(() => {
          document.getElementById('dropbtn_anhien').textContent = 'Ẩn Villa';
        },1000)
      }
      //document.getElementById('dropbtn_anhien').textContent

    }

    if (Choosegroup == 'Nhóm Thuê' && Neworedit == 'sửa giá' && option != 'Loại Sản Phẩm') {

      let result_gia = arr_suagia.find(item => item.tencan === option);
      // console.log('Đã chọn sản phẩm : xxxxxx', arr_suagia);
      // console.log('Đã chọn sản phẩm : xxxxxx', option);
      setobj_suagia(result_gia);
      console.log('Đã chọn sản phẩm : xxxxxx', result_gia);
      setsuagia_render(false);
      setTimeout(() => {
        setsuagia_render(true);

      }, 500)


    };


    setTypesanpham(option);
    //createID();// test gen ID
    //setChoosegroup(option);
    console.log('Đã chọn sản phẩm ok 2: ' + Choosegroup);
    if (Choosegroup == 'Nhóm Bán') {
      console.log("da ta sql: ", data_sql);
      const foundItem = data_sql.find(item => item.villaName === option); // lấy trong obj của sql phần tử obj có chứa sản phẩm đã chọn
      console.log("sản phẩm đã chọn: ", foundItem);
      //description
      setTimeout(() => {
        // Đoạn mã bạn muốn thực thi sau 5 giây
        setSuccessfulordersp("Hình ảnh");
        setChooseSanpham({ ...foundItem });
      }, 1000); // 5000 là thời gian độ trễ tính bằng mili giây (5 giây)


      setChooseSanpham({});

      // setchange_rend(Math.random());
      var str_infor = foundItem.description;
      var arr_st = str_infor.split("@T@");
      setChiTietNha(arr_st);
    };


   console.log('Tes chọn sản phẩm',Object.keys(ChooseSanpham).length)
   console.log('Tes chọn sản phẩm',Neworedit)
   console.log('Tes chọn sản phẩm',Typesanpham)

  }

  const xoasanpham = () => {
    console.log("xóa sản phẩm này");
    //https://tuongdoirong.com/api/node.php?f=deled&database=mindx&up_gt=banggiathue WHERE MaCan='9.6'
    console.log(ChooseSanpham);
    // let xoad = ChooseSanpham.villaPath;
    //console.log(xoad);

    socket.emit("xoahotel", { MaCan: ChooseSanpham.MaCan, villaPath: ChooseSanpham.villaPath });
  }

  const handleChange_chinhsua_tensp = (event) => {
    console.log('Giá trị mới của input:', event.target.value);
    // Xử lý logic của bạn ở đây
  };
  const handleBlur = (event) => {
    console.log('Con chuột đã rời khỏi input');
    // Xử lý logic của bạn ở đây
  };


  return (
    <div style={{ backgroundColor: 'white' }}>

      <div class="button-container" style={{ height: '200px', alignItems: 'flex-start' }}>

        <div class="dropdown">
          <button id="dropbtn" class="dropbtn">Nhóm Sản Phẩm</button>
          <div class="dropdown-content">
            {/* <a href="#" onClick={() => selectOption('Tùy chọn 1')}>Tùy chọn 1</a> */}
            {/* <a href="#" onClick={() => selectOption('Tùy chọn 2')}>Tùy chọn 2</a>
    <a href="#" onClick={() => selectOption('Tùy chọn 3')}>Tùy chọn 3</a> */}
            {dataRender.map((item, index) => {
              return (
                <div key={index}  >
                  <a href="#" onClick={() => selectOption(item)}>{item}</a>
                </div>
              )
            })}


          </div>

        </div>

        {/* Thêm mới hoặc chỉnh sửa */}
        {Choosegroup != '' && <div class="dropdown">
          <button id="dropbtn_config" class="dropbtn">Thao Tác</button>
          {(configvilla.length > 0 && Choosegroup == 'Nhóm Bán') && <div class="dropdown-content">

            {configvilla.map((item, index) => {
              return (
                <div key={index}  >
                  <a href="#" onClick={() => selectOption_config(item)}>{item}</a>
                </div>
              )
            })}


          </div>}

          {(configvilla_thue.length > 0 && Choosegroup == 'Nhóm Thuê') && <div class="dropdown-content">

            {configvilla_thue.map((item, index) => {
              return (
                <div key={index}  >
                  <a href="#" onClick={() => selectOption_config(item)}>{item}</a>
                </div>
              )
            })}


          </div>}

        </div>}

        {/* danh sách sản phẩm */}
        {(Choosegroup == 'Nhóm Bán' && Neworedit == 'Chỉnh Sửa') && <div class="dropdown">
          <button id="dropbtn_sanpham" class="dropbtn">Loại Sản Phẩm</button>
          {loaisanpham.length > 0 && <div class="dropdown-content">

            {loaisanpham.map((item, index) => {
              return (
                <div key={index}  >
                  <a href="#" onClick={() => selectOption_sanpham(item)}>{item}</a>
                </div>
              )
            })}


          </div>}

        </div>}






        {(Choosegroup == 'Nhóm Thuê' && (Neworedit == 'sửa giá' || Neworedit == 'Ẩn hiện Villa')) && <div class="dropdown">
          <button id="dropbtn_sanpham" class="dropbtn">Loại Sản Phẩm</button>
          {loaisanpham.length > 0 && <div class="dropdown-content">

            {loaisanpham.map((item, index) => {
              return (
                <div key={index}  >
                  <a href="#" onClick={() => selectOption_sanpham(item)}>{item}</a>
                </div>
              )
            })}


          </div>}

        </div>}



      </div>


      <div className="flex items-center justify-center bg-gray-100" style={{ marginBottom: '100px' }}>
        {(Choosegroup == 'Nhóm Thuê' && Neworedit == 'Ẩn hiện Villa' && Typesanpham != "Loại Sản Phẩm") && <div class="dropdown">
          <button id="dropbtn_anhien" class="dropbtn"> Ẩn Và Hiện Sản Phẩm </button>
          {anhien_vill.length > 0 && <div class="dropdown-content">

            {anhien_vill.map((item, index) => {
              return (
                <div key={index}  >
                  <a href="#" onClick={() => selectOption_anhien(item)}>{item}</a>
                </div>
              )
            })}


          </div>}

        </div>}

      </div>

      {/* Thêm mới nhà bán */}
      {(Choosegroup == 'Nhóm Bán' && Neworedit == 'Thêm mới') && <Form name="basic" labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} onFinish={onFinish_them} style={{ paddingLeft: '20px', paddingRight: '20px', backgroundColor: 'rgba(0, 128, 128, 0.4)' }}
        initialValues={{
          fullName: "VILLA 205 CẦN BÁN",
          tenphu: "CĂN VILLA 240M2",
          dientich: "Diện Tích Đất Là : 12x20",
          hientrang: "Hiện trạng: Hoàn thiện bên ngoài, thô bên trong",
          giaban: "Giá bán - số tiền cần thanh toán : 10.4 tỷ",
          detailgiaban: "11 tỷ (Đã bao gồm VAT)",
          detailtienthanhtoan: "10.4 Tỷ (Tương đương 95%)",
          detailloaihinh: "Biệt Thự đơn lập",
          detaildientich: "12x20m,240m2",
          detailxaydung: "150m2",
          detailtimenha: "nhận ngay",
          detailbangiao: "Hoàn thiện bên ngoài, thô bên trong",
          detailhuongnha: "Đông Bắc",
          detailphaply: "Hợp đồng mua bán",
          detailvaynganhang: "Bên bán không vay",
          detailbenmuavay: "Tối đa 70% giá trị HĐMB",
          detaildienthoai: "0967 953 754 - Văng Thành Ngây",
          detailstt: '0',
        }}

      >
        <Row gutter={[8, 0]} >

          <div style={{ left: '10px' }}>
            Số ID sản phẩm GEN ngẫu nhiên: <span style={{ color: 'red' }}>{newID}</span>

          </div>



          <Col span={24}>
            <Form.Item style={{ color: 'red' }}
              label={
                <span style={{ color: 'red' }}>Tên Sản Phẩm - Không Trùng khớp với tên có sẵn</span>
              }
              name="fullName"
            >

              <Input />

            </Form.Item>
          </Col>






          <Col span={12}>
            <Form.Item
              label={
                <span style={{ color: 'red' }}>List-Thêm Tên</span>
              }
              name="tenphu"

            >
              <Input />
            </Form.Item>
          </Col>



          <Col span={12}>
            <Form.Item
              label={
                <span style={{ color: 'red' }}>List-Diện Tích</span>
              }
              name="dientich"

            >
              <Input />
            </Form.Item>
          </Col>


          <Col span={24}>
            <Form.Item
              label={
                <span style={{ color: 'red' }}>List-Hiện Trạng</span>
              }
              name="hientrang"

            >
              <Input />
            </Form.Item>
          </Col>



          <Col span={24}>
            <Form.Item
              label={
                <span style={{ color: 'red' }}>List-Giá Bán</span>
              }
              name="giaban"

            >
              <Input />
            </Form.Item>
          </Col>



          <Col span={12}>
            <Form.Item
              label={
                <span style={{ color: 'red' }}>detail-Giá Bán</span>
              }
              name="detailgiaban"

            >
              <Input />
            </Form.Item>
          </Col>




          <Col span={12}>
            <Form.Item
              label={
                <span style={{ color: 'red' }}>detail-Số Tiền Thanh Toán</span>
              }
              name="detailtienthanhtoan"

            >
              <Input />
            </Form.Item>
          </Col>




          <Col span={12}>
            <Form.Item
              label={
                <span style={{ color: 'red' }}>detail-Loại Hình</span>
              }
              name="detailloaihinh"

            >
              <Input />
            </Form.Item>
          </Col>


          <Col span={12}>
            <Form.Item
              label={
                <span style={{ color: 'red' }}>detail-Diện Tích Đất</span>
              }
              name="detaildientich"

            >
              <Input />
            </Form.Item>
          </Col>



          <Col span={12}>
            <Form.Item
              label={
                <span style={{ color: 'red' }}>detail-Xây Dựng</span>
              }
              name="detailxaydung"

            >
              <Input />
            </Form.Item>
          </Col>




          <Col span={12}>
            <Form.Item
              label={
                <span style={{ color: 'red' }}>detail-Thời Gian Nhận Nhà</span>
              }
              name="detailtimenha"

            >
              <Input />
            </Form.Item>
          </Col>


          <Col span={24}>
            <Form.Item
              label={
                <span style={{ color: 'red' }}>detail-Tiêu Chuẩn Bàn Giao</span>
              }
              name="detailbangiao"

            >
              <Input />
            </Form.Item>
          </Col>



          <Col span={12}>
            <Form.Item
              label={
                <span style={{ color: 'red' }}>detail-Hướng Nhà</span>
              }
              name="detailhuongnha"

            >
              <Input defaultValue={ChiTietNha[7]} />
            </Form.Item>
          </Col>


          <Col span={12}>
            <Form.Item
              label={
                <span style={{ color: 'red' }}>detail-Pháp Lý</span>
              }
              name="detailphaply"

            >
              <Input />
            </Form.Item>
          </Col>


          <Col span={24}>
            <Form.Item
              label={
                <span style={{ color: 'red' }}>detail-Vay Ngân Hàng</span>
              }
              name="detailvaynganhang"

            >
              <Input />
            </Form.Item>
          </Col>




          <Col span={24}>
            <Form.Item
              label={
                <span style={{ color: 'red' }}>detail-Ngân Hàng Cho Bên Mua Vay</span>
              }
              name="detailbenmuavay"

            >
              <Input />
            </Form.Item>
          </Col>


          <Col span={24}>
            <Form.Item
              label={
                <span style={{ color: 'red' }}>detail-Điện Thoại</span>
              }
              name="detaildienthoai"

            >
              <Input />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item
              label={
                <span style={{ color: 'red' }}>detail-STT</span>
              }
              name="detailstt"

            >
              <Input />
            </Form.Item>
          </Col>



          <Col span={24} className="flex justify-center">
            <button className="inline-block rounded bg-gradient-to-r from-pink-500 via-red-500 to-yellow-500 p-[2px] hover:text-white focus:outline-none focus:ring active:text-opacity-75 ">
              <span className="block rounded-sm bg-white px-8 py-2 text-sm font-medium hover:bg-transparent  transition-all duration-[200ms] gap-1">
                <Send className="inline-block w-5 h-auto" /> <span style={{ color: textColor }}>{Successfulordernew}</span>
              </span>
            </button>
          </Col>



        </Row>
      </Form>}









      {(suagia_render && Choosegroup == 'Nhóm Thuê' && Neworedit == 'sửa giá' && Typesanpham != 'Loại Sản Phẩm') && <Form name="basic" labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} onFinish={onFinish_suagia} style={{ marginLeft: '20px', marginRight: '20px', backgroundColor: 'rgba(255, 255, 0, 0.2)' }}
        initialValues={{
          fullName: obj_suagia.tencan,
          tenphu: obj_suagia.gia_hai_nam,
          dientich: obj_suagia.gia_sau_bay,
          hientrang: obj_suagia.gia_ngayle,
        }}

      >
        <Row gutter={[8, 0]}>




          {/* handleChange_chinhsua_tensp */}
          <Col span={24}>
            <Form.Item
              label={
                <span style={{ color: 'red' }}>Tên Sản Phẩm</span>
              }
              name="fullName"
            >

              <Input readOnly={true} onChange={handleChange_chinhsua_tensp} onBlur={handleBlur} />

            </Form.Item>
          </Col>






          <Col span={12}>
            <Form.Item
              label={
                <span style={{ color: 'red' }}> Giá CN,2,3,4,Thứ 5</span>
              }
              name="tenphu"

            >
              <Input />
            </Form.Item>
          </Col>



          <Col span={12}>
            <Form.Item
              label={
                <span style={{ color: 'red' }}>Giá Thứ 6, Thứ 7</span>
              }
              name="dientich"

            >
              <Input />
            </Form.Item>
          </Col>


          <Col span={24}>
            <Form.Item
              label={
                <span style={{ color: 'red' }}>Giá Ngày Lễ</span>
              }
              name="hientrang"

            >
              <Input />
            </Form.Item>
          </Col>



          <Col span={24} className="flex justify-center">
            <button className="inline-block rounded bg-gradient-to-r from-pink-500 via-red-500 to-yellow-500 p-[2px] hover:text-white focus:outline-none focus:ring active:text-opacity-75 ">
              <span className="block rounded-sm bg-white px-8 py-2 text-sm font-medium hover:bg-transparent  transition-all duration-[200ms] gap-1">
                <Send className="inline-block w-5 h-auto" /> <span style={{ color: textColor }}>{Successfulorder_savegia}</span>
              </span>
            </button>
          </Col>



        </Row>
      </Form>}




      {(Object.keys(ChooseSanpham).length !== 0 && Neworedit == 'Chỉnh Sửa' && Typesanpham != 'Loại Sản Phẩm') && <Form name="basic" labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} onFinish={onFinish_sanpham} style={{ marginLeft: '20px', marginRight: '20px', backgroundColor: 'rgba(255, 255, 0, 0.2)' }}
        initialValues={{
          fullName: ChooseSanpham.villaName,
          tenphu: ChooseSanpham.rooms,
          dientich: ChooseSanpham.area,
          hientrang: ChooseSanpham.beds,
          giaban: ChooseSanpham.swim,
          detailgiaban: ChiTietNha[0],
          detailtienthanhtoan: ChiTietNha[1],
          detailloaihinh: ChiTietNha[2],
          detaildientich: ChiTietNha[3],
          detailxaydung: ChiTietNha[4],
          detailtimenha: ChiTietNha[6],
          detailbangiao: ChiTietNha[5],
          detailhuongnha: ChiTietNha[7],
          detailphaply: ChiTietNha[8],
          detailvaynganhang: ChiTietNha[9],
          detailbenmuavay: ChiTietNha[10],
          detaildienthoai: ChiTietNha[11],
          detailstt: ChooseSanpham.people[1],
        }}

      >
        <Row gutter={[8, 0]}>




          {/* handleChange_chinhsua_tensp */}
          <Col span={24}>
            <Form.Item
              label={
                <span style={{ color: 'red' }}>Tên Sản Phẩm</span>
              }
              name="fullName"
            >

              <Input readOnly={isReadOnly} onChange={handleChange_chinhsua_tensp} onBlur={handleBlur} />

            </Form.Item>
          </Col>






          <Col span={12}>
            <Form.Item
              label={
                <span style={{ color: 'red' }}>List-Thêm Tên</span>
              }
              name="tenphu"

            >
              <Input />
            </Form.Item>
          </Col>



          <Col span={12}>
            <Form.Item
              label={
                <span style={{ color: 'red' }}>List-Diện Tích</span>
              }
              name="dientich"

            >
              <Input />
            </Form.Item>
          </Col>


          <Col span={24}>
            <Form.Item
              label={
                <span style={{ color: 'red' }}>List-Hiện Trạng</span>
              }
              name="hientrang"

            >
              <Input />
            </Form.Item>
          </Col>



          <Col span={24}>
            <Form.Item
              label={
                <span style={{ color: 'red' }}>List-Giá Bán</span>
              }
              name="giaban"

            >
              <Input />
            </Form.Item>
          </Col>



          <Col span={12}>
            <Form.Item
              label={
                <span style={{ color: 'red' }}>detail-Giá Bán</span>
              }
              name="detailgiaban"

            >
              <Input />
            </Form.Item>
          </Col>




          <Col span={12}>
            <Form.Item
              label={
                <span style={{ color: 'red' }}>detail-Số Tiền Thanh Toán</span>
              }
              name="detailtienthanhtoan"

            >
              <Input />
            </Form.Item>
          </Col>




          <Col span={12}>
            <Form.Item
              label={
                <span style={{ color: 'red' }}>detail-Loại Hình</span>
              }
              name="detailloaihinh"

            >
              <Input />
            </Form.Item>
          </Col>


          <Col span={12}>
            <Form.Item
              label={
                <span style={{ color: 'red' }}>detail-Diện Tích Đất</span>
              }
              name="detaildientich"

            >
              <Input />
            </Form.Item>
          </Col>



          <Col span={12}>
            <Form.Item
              label={
                <span style={{ color: 'red' }}>detail-Xây Dựng</span>
              }
              name="detailxaydung"

            >
              <Input />
            </Form.Item>
          </Col>




          <Col span={12}>
            <Form.Item
              label={
                <span style={{ color: 'red' }}>detail-Thời Gian Nhận Nhà</span>
              }
              name="detailtimenha"

            >
              <Input />
            </Form.Item>
          </Col>


          <Col span={24}>
            <Form.Item
              label={
                <span style={{ color: 'red' }}>detail-Tiêu Chuẩn Bàn Giao</span>
              }
              name="detailbangiao"

            >
              <Input />
            </Form.Item>
          </Col>



          <Col span={12}>
            <Form.Item
              label={
                <span style={{ color: 'red' }}>detail-Hướng Nhà</span>
              }
              name="detailhuongnha"

            >
              <Input defaultValue={ChiTietNha[7]} />
            </Form.Item>
          </Col>


          <Col span={12}>
            <Form.Item
              label={
                <span style={{ color: 'red' }}>detail-Pháp Lý</span>
              }
              name="detailphaply"

            >
              <Input />
            </Form.Item>
          </Col>


          <Col span={24}>
            <Form.Item
              label={
                <span style={{ color: 'red' }}>detail-Vay Ngân Hàng</span>
              }
              name="detailvaynganhang"

            >
              <Input />
            </Form.Item>
          </Col>




          <Col span={24}>
            <Form.Item
              label={
                <span style={{ color: 'red' }}>detail-Ngân Hàng Cho Bên Mua Vay</span>
              }
              name="detailbenmuavay"

            >
              <Input />
            </Form.Item>
          </Col>


          <Col span={24}>
            <Form.Item
              label={
                <span style={{ color: 'red' }}>detail-Điện Thoại</span>
              }
              name="detaildienthoai"

            >
              <Input />
            </Form.Item>
          </Col>


          <Col span={24}>
            <Form.Item
              label={
                <span style={{ color: 'red' }}>detail-STT</span>
              }
              name="detailstt"

            >
              <Input />
            </Form.Item>
          </Col>

          {suaten && <div style={{ color: 'red' }}>Bạn Vừa Chỉnh Sửa Tên Sản Phẩm nên Bạn sẽ không thể Chỉnh Sửa hình ảnh, nếu bạn muốn chỉnh sửa hình ảnh, vui lòng bấm lưu về máy chủ, sau đó thao tác lại để sửa ảnh</div>}

          <Col span={24} className="flex justify-center">
            <button className="inline-block rounded bg-gradient-to-r from-pink-500 via-red-500 to-yellow-500 p-[2px] hover:text-white focus:outline-none focus:ring active:text-opacity-75 ">
              <span className="block rounded-sm bg-white px-8 py-2 text-sm font-medium hover:bg-transparent  transition-all duration-[200ms] gap-1">
                <Send className="inline-block w-5 h-auto" /> <span style={{ color: textColor }}>{Successfulordersp}</span>
              </span>
            </button>
          </Col>



        </Row>
      </Form>}












      <div className={`flex flex-col ${className}  `}>
        <div className="pb-1 md:pb-3 font-thena">

        </div>
        <Form name="basic" labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} onFinish={onFinish}>
          <Row gutter={[8, 0]}>


            {/* <Col span={12}>
              <Form.Item
                label="Số điện thoại"
                name="phone"
                rules={[
                  {
                    required: true,
                    message: 'Vui lòng nhập!'
                  },
                  {
                    pattern: new RegExp(/(84|0[3|5|7|8|9])+([0-9]{8})\b/),
                    message: 'Vui lòng nhập đúng số điện thoại!'
                  }
                ]}
              >
                <Input id="FORMDANGKY_NAME" />

              </Form.Item>
            </Col> */}


            <Col span={12}>
              <Form.Item
                label="Không gõ vào đây : ./duan/images/testnhe"
                name="fullName"
                rules={[
                  {
                    required: true,
                    message: 'Vui lòng nhập!'
                  }
                ]}
              >
                <Input />
              </Form.Item>
            </Col>




            <Col span={24} className="flex justify-center">
              <button className="inline-block rounded bg-gradient-to-r from-pink-500 via-red-500 to-yellow-500 p-[2px] hover:text-white focus:outline-none focus:ring active:text-opacity-75 ">
                <span className="block rounded-sm bg-white px-8 py-2 text-sm font-medium hover:bg-transparent  transition-all duration-[200ms] gap-1">
                  <Send className="inline-block w-5 h-auto" /> <span style={{ color: textColor }}>{Successfulorder}</span>
                </span>
              </button>
            </Col>



          </Row>
        </Form>
        {/* {Renderupload && <UploadImages /> } */}
        {Renderupload && <NovaMemories img={Linkurl} url={Urlinput} />}
        <div style={{ height: '100px', marginTop: '50px', display: 'flex', justifyContent: 'center' }}>

          {(Choosegroup == 'Nhóm Bán' && Neworedit == 'Chỉnh Sửa' && Typesanpham != 'Loại Sản Phẩm') && <Button onClick={xoasanpham}>Xóa sản phẩm này</Button>}

        </div>


      </div>
    </div>
  )
}
