'use client'


// import { NovaContext } from 'src/components/Nova'
// import { Call } from 'src/icons/Call'
import { View360 } from 'src/icons/View360'

import React, { useEffect, useState, useContext } from 'react'
//import { CallButton } from './CallButton'

import { Context } from "../../../context_global/Context";

import backdau from '../../../images/tieu-de-bg.jpg'
//H:\data\website\web_nova\client_web\src\components\Nova\Header\index.jsx
// ../../../images/tieu-de-bg.jpg
//C:\Users\pchai\Desktop\novaland-ho-tram-main-cra-js\src\components\Nova\Header\index.jsx


//cilent_nova_socket/src/components/Nova/Header/index.jsx

export const NovaHeader = () => {
  const { setfinshHeard } = useContext(Context);
  const [isLoaded, setIsLoaded] = useState(false);
 
  const pathname = window.location.pathname;
  //console.log("jjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjj");
  //console.log(pathname);
  // const { setOpenForm } = React.useContext(NovaContext)

  useEffect(() => {
    if (isLoaded) {
      console.log('ành heard đã load xong');
      setfinshHeard(true);
      // Thực hiện các hành động cần thiết khi ảnh đã load xong
    }
  }, [isLoaded]);

  useEffect(() => {
    // document.getElementById('NOVA_HEADER')?.scrollIntoView({ behavior: 'smooth' })
  }, [pathname])

  return (
    <section id="NOVA_HEADER">
      <div className="w-full relative">
        {/* <div className="absolute h-1/3 left-0 right-0 bottom-0 bg-blue-500 bg-opacity-20" /> */}

        <img onLoad={() => setIsLoaded(true)} src={backdau} width={'100%'} alt="" className="brightness-95" />

        <div className="md:absolute w-full md:top-24 md:left-1/2 transform md:-translate-x-1/2 flex flex-col items-center">
          <h2 className="font-thena text-5xl sm:text-7xl lg:text-8xl font-bold text-shadow relative right-8 md:text-emerald-400 text-gray-950">
            Villa Novaworld
          </h2>
          <h2 className="font-thena text-5xl sm:text-7xl lg:text-8xl text-shadow relative left-16 md:left-32 text-blue-500 md:text-white font-semibold ">
            Hồ Tràm
          </h2>
        </div>
        <div className=" md:absolute w-full md:left-1/2 transform md:-translate-x-1/2 flex flex-col items-center bottom-0 lg:bottom-4">
          <h3 className="text-full-colors text-md sm:text-xl lg:text-3xl font-bold">
            kỳ nghỉ đáng nhớ của riêng bạn
          </h3>
          {/* <h2 className="text-full-colors text-md sm:text-xl font-bold" style={{fontSize : "12px"}}>(Khách sạn giá 100k/1 đêm)</h2> */}
          <div className="flex gap-4 md:gap-4 mt-2 md:mt-2 lg:mt-6 relative ">
            <a
              href="https://bds.vr360plus.vn/projects/nova-world-ho-tram/"
              target="_blank"
              rel="noreferrer"
              className="shadow hover:shadow-xl hover:cursor-pointer transition-all duration-200 p-2 md:p-3 rounded text-center font-bold flex gap-2 h-10 text-xs md:text-base items-center"
            >
              <View360 className="inline-block" /> 360° TOÀN CẢNH
            </a>
     {/*        <CallButton /> */}
          </div>
        </div>
      </div>
    </section>
  )
}
