'use client'
import { createContext, useState } from 'react'

export const NovaContext = createContext({
  openForm: false,
  setOpenForm: () => null
})

export default function NovaContainer({ children }) {
  const [openForm, setOpenForm] = useState(false)
  return <NovaContext.Provider value={{ openForm, setOpenForm }}>{children}</NovaContext.Provider>
}
