import React, { useEffect, useState, useContext } from 'react'
import { NovaTopHeader } from './components/Nova/TopHeader'
import { NovaFooter } from './components/Nova/Footer'



export const Layout = ({ children }) => {
 

  const [renderFooter, setrenderFooter] = useState(false);
 setTimeout(()=>{
  setrenderFooter(true);

 } , 15000);


  useEffect(() => {
    // scroll to top
    window.scrollTo(0, 0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.pathname])



  return (
    <main className="">
      <NovaTopHeader />
      {children}
    { (renderFooter) && <NovaFooter /> }
    </main>
  )
}
