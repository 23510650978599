import { Modal } from 'antd'
import React from 'react'
import { RegisterForm } from '../RegisterForm'
//import { CallButton } from '../Header/CallButton'
import { NovaContext } from '..'

export const RegisterModal = () => {
  const { openForm, setOpenForm } = React.useContext(NovaContext)
  return (
    <div id="form_modal">
   {/*    <CallButton onClick={() => setOpenForm(true)} /> */}
      <Modal
        open={openForm}
        onCancel={() => {
          setOpenForm(false)
        }}
        getContainer={() => document.getElementById('form_modal')}
        footer={null}
        destroyOnClose
      >
        <RegisterForm />
      </Modal>
    </div>
  )
}
