'use client'
import React from 'react'

import { useState, useEffect, useContext } from "react";
import { Context } from "../../context_global/Context";

import { socket } from '../../socket_server/socket';

import { ImageGallery } from '../../components/ImageGallery'
//import { DATA_ROOMS, DATA_ROOMS_OBJ } from '../../mocks/rooms'
import { Area } from '../../icons/Area'
import { HotelRoom } from '../../icons/HotelRoom'
import { Bed } from '../../icons/Bed'
import { People } from '../../icons/People'
import { WC } from '../../icons/WC'
import { RegisterModal } from '../../components/Nova/RegisterModal'
import NovaContainer from '../../components/Nova'
import { NovaBookRoom } from '../../components/Nova/BookRoom'

import { NovaBookhotel } from '../../components/Nova/Bookhotel'

import { Swimming } from '../../icons/Swimming'
import { Party } from '../../icons/Party'
import { Layout } from '../../Layout'
import { Villaprice } from '../../icons/Villaprice'

import { formatCurrency } from '../../function/utilities'
import { includes, keyBy } from 'lodash-es'

import Banner from '../../Banner/Banner';

import { Bookroomalldate } from '../../Bookroomalldate'

//C:\Users\pchai\OneDrive\MaychuNodejs\VPS_web\server_nodejs_web\nghiencua_socket_choweb_nova\thu_nghiem_serersocket_nodejs_webstie\cilent_nova_socket\src\pages\room\index.jsx
//C:\Users\pchai\OneDrive\MaychuNodejs\VPS_web\server_nodejs_web\nghiencua_socket_choweb_nova\thu_nghiem_serersocket_nodejs_webstie\cilent_nova_socket\src\pages\room\index.jsx
//cilent_nova_socket/src/pages/room/index.jsx
// cilent_nova_socket/src/pages/room/index.jsx
//C:\Users\pchai\OneDrive\Tài liệu\dongbo\noi_lam_viec\reac-web\novaland-ho-tram-main-cra-js\src\pages\room\index.jsx
export default function RoomPage({ params }) {
  //console.log(4444444444444444);
  //console.log(params)
  // params = {id : jolene-villa }



  const [DATA_ROOMS_OBJ, setDATA_ROOMS_OBJ] = useState([]);

  
 

  useEffect(() => {

    socket.emit("allrouter", {});

    socket.on('allrouter', msg => { // có tin nhắn tới với key = 'chat message'
      console.log("Chi tiết room");
      console.log(msg.allrouter);

      let villaload = keyBy(msg.allrouter, 'villaPath');
      //console.log(villaload);
      setDATA_ROOMS_OBJ(villaload);

    });


  }, [])

  if (DATA_ROOMS_OBJ != []) {
    console.log("Render lại mẹ  xxxx");

  // params.id = "jolene-villa";
  // DATA_ROOMS_OBJ  là một obj được convert từ mảng , mỗi phần tử của obj đều có tên là chuỗi : jolene-villa
  // khi routert vào một chi tiết sản phẩm định vị bởi path = /jolene-villa
  const room = DATA_ROOMS_OBJ?.[params.id] || {};
  console.log("room")
  console.log(room)

//item.images?.galleries[0].original
// original: `/images/rooms/${roomImages.path}/_${i + 1}.jpg`,
// thumbnail: `/images/rooms/${roomImages.path}/_${i + 1}.jpg`

  const roomImages = DATA_ROOMS_OBJ?.[params.id]?.images || {}
//client_web/src/pages/room/index.jsx

  const galleries = Array.from({ length: roomImages.length }, (_, i) => ({
    original: `/images/rooms/${roomImages.path}/${room.galleries[i].original}`,
    thumbnail: `/images/rooms/${roomImages.path}/${room.galleries[i].original}`
  }))
  /* 
  Nó sẽ trả về một mảng : galleries = [p1,p2 ]
  mà p1 = {original : "link", thumbnail : 'link'  }
  .. p2 =
  mảng này có số phần tử là số lượng : roomImages.length.
  
  */

  /*  console.log("console thử thôi mà");
   console.log(galleries); */


  //alert("Render lại mẹ");

  /*   function formatCurrency(value) {
      // Chuyển đổi chuỗi số thành số nguyên
      const numberValue = parseInt(value, 10);
    
      // Kiểm tra nếu numberValue là một số hợp lệ
      if (!isNaN(numberValue)) {
        // Định dạng số thành chuỗi với ngăn cách hàng ngàn
        const formattedValue = numberValue.toLocaleString('vi-VN');
        return formattedValue;
      }
    
      // Trả về chuỗi gốc nếu không thể định dạng
      return value;
    }
     */


  // let str = room.code;
  // let parts = str.split(" ");
  // for (const part of parts) {
  //   const floatValue = parseFloat(part);
  //   if (!isNaN(floatValue)) {
  //     console.log("Số thực:", floatValue);
  //     // gửi socket về cho server
  //     socket.emit("price", { floatValue });

  //     break;
  //   }
  // }




  // //  // nhận câu trả lời từ server
  // socket.on('price', msg => { // có tin nhắn tới với key = 'chat message'
  //   //alert(msg.gui_cilent_price)
  //   // console.log("msg:", msg.gui_cilent_price);
  //   setpricevilla(msg.gui_cilent_price);


  // });
  let check_type = false;
  let check_type_name = "VILLA";
  if (room.villaName !== undefined) {
    let stringname = room.villaName;
    //alert(stringname);
    check_type = stringname.includes("HOTEL") || stringname.includes("CONTAINER") || stringname.includes("HOMESTAY");
    if (stringname.includes("HOTEL") == true) {
      check_type_name = "HOTEL";
    }
    if (stringname.includes("CONTAINER") == true) {
      check_type_name = "CONTAINER";
    }
    if (stringname.includes("HOMESTAY") == true) {
      check_type_name = "HOMESTAY";
    }
    if (stringname.includes("CẦN BÁN") == true) {
      check_type_name = "CẦN BÁN";
    }
    /*    console.log(88888888888)
       console.log(check_type) */
  }

  if (check_type_name == "CẦN BÁN"){
   var str_infor = room.description;
   var arr_st = str_infor.split("@T@");
   console.log("////////////////////////");
   console.log(arr_st);
  };

  


 
    //alert(roomImages.path);
    return (
      <Layout >
        <NovaContainer >
          <main className="mt-12 md:mt-24 " style={{ backgroundColor: 'white' }} >


            <section className="max-w-screen-lg m-auto flex gap-8 flex-wrap px-3 md:px-0 bg-gray-300">

              <div className="w-full md:w-[55%] bg-gray-200">
                {/* Nó sẽ trả về một mảng : galleries = [p1,p2 ]
                mà p1 = {original : "link", thumbnail : 'link'  }
                .. p2 = 
                props = {
                  items : galleries,
                  autoPlay,
                }
                */}
                <ImageGallery items={galleries} autoPlay />
              </div>

              <div className="flex flex-col gap-2 flex-1 bg-gray-300">
                {/* text-3xl md:text-5xl */}
                <h1  className="text-3xl md:text-4xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-purple-500 to-pink-600 drop-shadow" style={{marginTop : '10px'}}>
                     {room.villaName}
                     
                </h1>

               {check_type_name != "CẦN BÁN" && <p className="text-lg italic bg-blue-100 w-fit px-2 rounded">Mã: {room.code}</p>}
               {check_type_name == "CẦN BÁN" && <p className="text-lg italic bg-blue-100 w-fit px-2 rounded">Mã: {room.villaName}</p>}
           

               {check_type_name == "CẦN BÁN" && <p className="text-base">{room.rooms}</p>}

               {check_type_name == "CẦN BÁN" && (<p> <span className="text-sm md:text-xl italic bg-blue-100 w-fit px-2 rounded"> GIÁ BÁN :</span> <span style={{backgroundColor : 'rgba(1, 2, 3, 0)'}}>{arr_st[0]}</span></p>)}
               {check_type_name == "CẦN BÁN" && (<p> <span className="text-sm md:text-xl italic bg-blue-100 w-fit px-2 rounded"> SỐ TIỀN THANH TOÁN:</span> <span style={{backgroundColor : 'rgba(1, 2, 3, 0)'}}>{arr_st[1]}</span></p>)}

               {check_type_name == "CẦN BÁN" && (<p style={{marginTop : '20px'}}> <span className="text-sm md:text-xl italic bg-blue-100 w-fit px-2 rounded"> LOẠI HÌNH:</span> <span style={{backgroundColor : 'rgba(1, 2, 3, 0)'}}>{arr_st[2]}</span></p>)}
               
               {check_type_name == "CẦN BÁN" && (<p style={{marginTop : '20px'}}> <span className="text-sm md:text-xl italic bg-blue-100 w-fit px-2 rounded"> DIỆN TÍCH ĐẤT:</span> <span style={{backgroundColor : 'rgba(1, 2, 3, 0)'}}>{arr_st[3]}</span></p>)}
               {check_type_name == "CẦN BÁN" && (<p> <span className="text-sm md:text-xl italic bg-blue-100 w-fit px-2 rounded"> XÂY DỰNG:</span> <span style={{backgroundColor : 'rgba(1, 2, 3, 0)'}}>{arr_st[4]}</span></p>)}

               {check_type_name == "CẦN BÁN" && (<p style={{marginTop : '20px'}}> <span className="font-bold text-sm md:text-xl italic w-fit px-2 rounded"> TÌNH TRẠNG NHẬN NHÀ:</span> </p>)}
               {check_type_name == "CẦN BÁN" && (<p> <span className="text-sm md:text-xl italic bg-blue-100 w-fit px-2 rounded"> TIÊU CHUẨN BÀN GIAO:</span> <span style={{backgroundColor : 'rgba(1, 2, 3, 0)'}}>{arr_st[5]}</span></p>)}
               {check_type_name == "CẦN BÁN" && (<p> <span className="text-sm md:text-xl italic bg-blue-100 w-fit px-2 rounded"> THỜI GIAN NHẬN NHÀ:</span> <span style={{backgroundColor : 'rgba(1, 2, 3, 0)'}}>{arr_st[6]}</span></p>)}
               
               {check_type_name == "CẦN BÁN" && (<p style={{marginTop : '20px'}}> <span className="font-bold text-sm md:text-xl italic w-fit px-2 rounded"> HƯỚNG NHÀ:</span> <span style={{backgroundColor : 'rgba(1, 2, 3, 0)'}}>{arr_st[7]}</span></p>)}
               {check_type_name == "CẦN BÁN" && (<p > <span className="font-bold text-sm md:text-xl italic w-fit px-2 rounded"> PHÁP LÝ:</span> <span style={{backgroundColor : 'rgba(1, 2, 3, 0)'}}>{arr_st[8]}</span></p>)}
               {check_type_name == "CẦN BÁN" && (<p > <span className="font-bold text-sm md:text-xl italic w-fit px-2 rounded"> VAY NGÂN HÀNG:</span> <span style={{backgroundColor : 'rgba(1, 2, 3, 0)'}}>{arr_st[9]}</span></p>)}
               {check_type_name == "CẦN BÁN" && (<p > <span className="font-bold text-sm md:text-xl italic w-fit px-2 rounded"> NGÂN HÀNG CHO BÊN MUA VAY:</span> <span style={{backgroundColor : 'rgba(1, 2, 3, 0)'}}>{arr_st[10]}</span></p>)}
               
               {check_type_name == "CẦN BÁN" && (<p style={{marginTop : '30px'}}> <span className="font-bold text-sm md:text-xl italic w-fit px-2 rounded"> LIÊN HỆ TƯ VẤN VÀ XEM NHÀ:</span></p>)}
               {check_type_name == "CẦN BÁN" && (<p > <span className="font-bold text-sm md:text-xl italic w-fit px-2 rounded"> ĐIỆN THOẠI:</span> <span style={{backgroundColor : 'rgba(1, 2, 3, 0)'}}>{arr_st[11]}</span></p>)}
                
               {check_type_name != "CẦN BÁN"  && <p className="text-base">{room.description}</p>}

                {check_type_name != "CẦN BÁN"  && <div className="flex flex-col gap-2 mt-5">
                  <div className="grid grid-cols-6 gap-1">
                    <p className="text-base font-light col-span-2">Diện tích:</p>
                    <p className="text-lg font-bold col-span-4">
                      <Area className="inline-block text-lg w-5 h-auto mr-1" />
                      {room.area}m<sup>2</sup>
                    </p>
                  </div>
                  <div className="grid grid-cols-6 gap-1">
                    <p className="text-base font-light col-span-2">Phòng ngủ:</p>
                    <p className="text-lg font-bold col-span-4">
                      <HotelRoom className="inline-block text-lg w-5 h-auto mr-1" />
                      {room.rooms} phòng
                    </p>
                  </div>
                  <div className="grid grid-cols-6 gap-1">
                    <p className="text-base font-light col-span-2">WC:</p>
                    <p className="text-lg font-bold col-span-4">
                      <WC className="inline-block text-lg w-5 h-auto mr-1" />
                      {room.wc} phòng
                    </p>
                  </div>

                  <div className="grid grid-cols-6 gap-1">
                    <p className="text-base font-light col-span-2">Giường:</p>
                    <p className="text-lg font-bold col-span-4">
                      <Bed className="inline-block text-lg w-5 h-auto mr-1" />
                      {room.beds} giường
                    </p>
                  </div>

                  <div className="grid grid-cols-6 gap-1">
                    <p className="text-base font-light col-span-2">Sức chứa:</p>
                    <p className="text-lg font-bold col-span-4">
                      <People className="inline-block text-lg w-5 h-auto mr-1" />
                      {/* {room.people?.[0]} - {room.people?.[1]} người */}
                      {check_type ? (<span>Một giường 2 người</span>) : ( (room.rooms != 'x') && <span>Không quá 10 người</span>)}
                    </p>
                  </div>




                  {room.swimmingPool ? (
                    <div className="grid grid-cols-6 gap-1">
                      <p className="text-base font-light col-span-2">Hồ bơi:</p>
                      <p className="text-lg font-bold col-span-4">
                        <Swimming className="inline-block text-lg w-5 h-auto mr-1" />
                        hồ bơi riêng
                      </p>
                    </div>
                  ) : null}
                  {room.giftParty ? (
                    <div className="grid grid-cols-6 gap-1">
                      <p className="text-base font-light col-span-2">Pary:</p>
                      <p className="text-lg font-bold col-span-4">
                        <Party className="inline-block text-lg w-5 h-auto mr-1" />
                        Tặng bàn party
                      </p>
                    </div>
                  ) : null}

                  <div className="grid grid-cols-6 gap-1">
                    <p className="text-base font-light col-span-2">Giá Hôm Nay:</p>
                    <p className="text-lg font-bold col-span-4">
                      <Villaprice className="inline-block text-lg w-5 h-auto mr-1" />
                      <span className='text-red-500'>
                        {formatCurrency(room.price)} VND
                      </span>

                    </p>
                  </div>

                </div> }
               {/* nơi đặt phòng, đặt villa */}

               {/* {check_type_name != "CẦN BÁN"  && <Bookroomalldate className="p-5" MaCan={room.MaCan} roomtype={check_type_name} codetype={room.code} />} */}


               {check_type_name != "CẦN BÁN"  && <p className="italic font-light text-gray-700 mt-8">
                  Happy Stay Resort thường có nhiều chương trình ưu đãi dành cho khách hàng mới và cũ,
                  hãy liên hệ với chúng tôi để nhận nhiều ưu đãi.
                </p> }

                <RegisterModal />
              </div>


            </section>

     
            {check_type_name == "CẦN BÁN" && <div> <NovaBookhotel />  <NovaBookRoom />   </div> }
            {check_type_name != "CẦN BÁN" && <div>  <NovaBookRoom />  <NovaBookhotel />  </div> }
          {/*   <Banner /> */}
            

          </main>


        </NovaContainer>
      </Layout>
    )


  }






}

