import React from 'react'

export const Contacts = () => {
  return (
    <div className="flex flex-col gap-2 fixed bottom-3 right-3 z-50">
      <a
        href="https://m.me/119290697818315"
        rel="noreferrer"
        target="_blank"
        className="text-gray-700 transition hover:text-gray-700/75"
      >
        <img src={'/fonts/messenger.svg'} alt="" className="h-auto w-12 md:w-16" />
      </a>
      <a
        href="https://zalo.me/0706257968"
        rel="noreferrer"
        target="_blank"
        className="text-gray-700 transition hover:text-gray-700/75"
      >
        <img src={'/fonts/zalo.svg'} alt="" className="h-auto w-12 md:w-16" />
      </a>
    </div>
  )
}
