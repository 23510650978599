

import React, { useState, useEffect } from 'react';
import { default as RCImageGallery } from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import { useContext } from 'react';
import { Context } from "../context_global/Context";
import { ControlOutlined } from '@ant-design/icons';
import { socket } from '../socket_server/socket';
import UploadImages from './UploadImages';

import { Col, DatePicker as AntDatePicker, Form, Input, Row, InputNumber, message } from 'antd'
import dayjs from 'dayjs'
import { Send } from './Send'
const ImageGalleryCmp = RCImageGallery;


export const ImageGallery = (props) => {
  const { imght, setimght } = useContext(Context);



  useEffect(() => {



  }, []);


  useEffect(() => {
    // set full .image-gallery-image equal with
    const images = document.querySelectorAll('.image-gallery-image');
    images.forEach((img) => {
      img.style.height = img.clientWidth + 'px';
    });
  }, [props.items]);

  const onScreenChange = (fullScreenElement) => {
    if (fullScreenElement) {
      const images = document.querySelectorAll('.fullscreen .image-gallery-image');
      images.forEach((img) => {
        img.style.height = 'calc(100vh - 120px)';
        // img.style.height = 'auto';
      });
    }
  };




  const handleCustomButtonClick = () => {
    console.log("8888888888")

    //setimght( imght.replace('./', './duan/'));
    let sendserver = imght.replace('./', './duan/');
    console.log(imght);
    console.log(sendserver);
    socket.emit("xoa_anh", { linkxoa: sendserver });

  };






  return (
    <div>
      <div class="button-container">
        <UploadImages />
        <button onClick={handleCustomButtonClick}>Xóa ảnh-Cẩn Thận</button>

      </div>

      <ImageGalleryCmp
        {...props}
        onScreenChange={onScreenChange}
        onSlide={(index) => {
          // console.log('Chỉ số của hình ảnh đang hiển thị:', index);
          const imageName = props.items[index].original; // Thay 'original' bằng thuộc tính tên hình ảnh mà bạn muốn log
          // console.log('Tên của hình ảnh đang hiển thị:', imageName);
          setimght(imageName);

        }}

      />









    </div>
  );
};

export default ImageGallery;

