'use client'
import { Staryellow } from '../../../icons/Staryellow';
import React, { useState } from 'react'
import { useWindowSize } from 'react-use'
//C:\Users\pchai\OneDrive\MaychuNodejs\VPS_web\server_nodejs_web\nghiencua_socket_choweb_nova\thu_nghiem_serersocket_nodejs_webstie\cilent_nova_socket\src\components\Nova\IntroVideo\index.jsx
export const NovaIntroVideo = (props) => {
  const [showVideo, setShowVideo] = useState(false)
  const { width } = useWindowSize()

  return (
    <div id="NovaIntroVideo" className="w-full mt-10 bg-gray-300">
      <div className="max-w-screen-lg mx-auto px-4 md:px-0">
        <h2 className="font-thena text-4xl md:text-6xl text-center">
        
          { (props.type == 0) && <Staryellow tex = "Tương Đương" type = {1} /> }
          { (props.type == 1) && <Staryellow tex = "Hoạt động khám phá trải nghiệm" type = {0}/> }
          
          </h2>

       { (props.type == 0) &&  <div className="font-quick-sand text-center text-sm md:text-lg">
        Trải nghiệm tuyệt vời tại resort biển danh tiếng trên thế giới tại Hồ Tràm
        </div> }

      </div>
      <div className="max-w-screen-md m-auto relative mt-12">
        <iframe
          width="100%"
          height={width <= 604 ? 216 : 432}
          // src={`https://www.youtube.com/embed/4oo9F_y2_T0?${showVideo ? 'autoplay=1' : ''}`}
          src={`${props.linkvideo}?${showVideo ? 'autoplay=1' : ''}`}
          // src={`https://www.youtube.com/embed/lKIxBP4K4O4?${showVideo ? 'autoplay=1' : ''}`}
          //props
          // https://www.youtube.com/shorts/lKIxBP4K4O4 
          title="Villa Novaworld Hồ Tràm - Đón sóng cùng chủ nhà và tận hưởng ngày hè cùng gia đình và bạn bè"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
          style={{
            display: showVideo ? 'block' : 'none'
          }}
        ></iframe>
        <div
          className={`max-w-screen-md m-auto h-[${width < 604 ? 216 : 432}px] cursor-pointer`}
          style={{ display: showVideo ? 'none' : 'block' }}
          onClick={() => setShowVideo(true)}
        >
          {/* <img src="/images/video-intro.png" alt="" className="bg-contain w-full" /> */}
          <img src={props.linkimg} alt="" className="bg-contain w-full" />
          <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 ">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="56px"
              height="56px"
              preserveAspectRatio="none"
              viewBox="0 0 408.7 408.7"
              fill="rgba(0, 0, 0, 0.5)"
            >
              <polygon fill="#fff" points="163.5 296.3 286.1 204.3 163.5 112.4 163.5 296.3" />
              <path
                d="M204.3,0C91.5,0,0,91.5,0,204.3S91.5,408.7,204.3,408.7s204.3-91.5,204.3-204.3S316.7,0,204.3,0ZM163.5,296.3V112.4l122.6,91.9Z"
                transform="translate(0 0)"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
  )
}
