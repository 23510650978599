export const Parking = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
      enableBackground="new 0 0 100 100"
      xmlSpace="preserve"
      preserveAspectRatio="none"
      width="30px"
      height="30px"
      className=""
      fill="rgba(0, 102, 171, 1)"
      {...props}
    >
      <defs>
        <linearGradient gradientTransform="rotate(0)">
          <stop offset="0%" stopColor="#000" />
          <stop offset="100%" stopColor="#000" />
        </linearGradient>
      </defs>
      <g>
        <path d="M81.8,4.9H18C10.8,4.9,4.9,10.8,4.9,18v63.8c0,7.2,5.9,13.1,13.1,13.1h63.8c7.2,0,13.1-5.9,13.1-13.1V18   C94.9,10.8,89,4.9,81.8,4.9z M91.7,81.8c0,5.5-4.5,9.9-9.9,9.9H18c-5.5,0-9.9-4.5-9.9-9.9V18c0-5.5,4.5-9.9,9.9-9.9h63.8   c5.5,0,9.9,4.5,9.9,9.9V81.8z" />
        <path d="M66.1,70.5c-0.8,0.2-1.4,0.8-1.6,1.6c-0.3,1.4,0.9,2.6,2.4,2.4c0.8-0.1,1.4-0.8,1.6-1.6C68.7,71.5,67.5,70.2,66.1,70.5z" />
        <path d="M45.8,61.5h8.3l0-6.1C46.4,56.3,45.8,61.5,45.8,61.5z" />
        <path d="M47.1,70.5c-0.8,0.2-1.4,0.8-1.6,1.6c-0.3,1.4,0.9,2.6,2.4,2.4c0.8-0.1,1.4-0.8,1.6-1.6C49.8,71.5,48.5,70.2,47.1,70.5z" />
        <path d="M39.7,39.8c0.9-0.2,1.7-0.6,2.3-1.1c0.6-0.5,1-1.1,1.3-1.7c0.3-0.7,0.4-1.5,0.4-2.4c0-1.1-0.2-2-0.6-2.6   c-0.4-0.7-0.9-1.2-1.6-1.6c-0.7-0.4-1.5-0.6-2.4-0.8c-0.9-0.1-1.8-0.2-2.8-0.2h-7.8v10.7h8C37.6,40.2,38.7,40.1,39.7,39.8z" />
        <path d="M57.6,63.7h-1.1c-0.3,0-0.6,0.3-0.5,0.7c0.1,0.2,0.3,0.3,0.5,0.3h1.1c0.3,0,0.6-0.3,0.5-0.7C58.1,63.8,57.9,63.7,57.6,63.7   z" />
        <path d="M78.7,12.2H21c-4.9,0-8.9,4-8.9,8.9v57.7c0,4.9,4,8.9,8.9,8.9h57.7c4.9,0,8.9-4,8.9-8.9V21.1   C87.6,16.2,83.7,12.2,78.7,12.2z M28.3,61.1h-7.1V22.9h14.7c1.3,0,2.6,0.1,3.8,0.2c1.3,0.1,2.4,0.4,3.6,0.7   c1.1,0.4,2.1,0.8,3.1,1.4c0.9,0.6,1.7,1.3,2.4,2.2c0.7,0.9,1.2,1.9,1.6,3.1c0.4,1.2,0.6,2.5,0.6,4.1c0,4.1-1.2,7.1-3.7,9.2   c-2.5,2-6.1,3.1-11,3.1h-7.9V61.1z M35.3,71.3c0,0-0.5-0.5,0-2.3c0,0,2-1.3,2,2.3H35.3z M47,76.1c-1.6-0.2-3-1.5-3.2-3.2   c-0.3-2.4,1.7-4.4,4.1-4.1c1.6,0.2,3,1.5,3.2,3.2C51.5,74.4,49.5,76.4,47,76.1z M66,76.1c-1.6-0.2-3-1.5-3.2-3.2   c-0.3-2.4,1.7-4.4,4.1-4.1c1.6,0.2,3,1.5,3.2,3.2C70.4,74.4,68.4,76.4,66,76.1z M79.5,70.4c0,0.4-0.4,0.8-0.8,0.8h-2.2   c-0.4,0-0.8-0.4-0.8-0.8v-1.6c0-0.4,0.4-0.8,0.8-0.8h2.2c0.4,0,0.8,0.4,0.8,0.8V70.4z M79.5,66.4v0.7H76c-0.8,0-1.4,0.6-1.4,1.4   v2.8h-0.2h-2h-1.1c-0.5-2.2-2.5-3.9-4.9-3.9c-2.4,0-4.4,1.7-4.9,3.9h-9c-0.5-2.2-2.5-3.9-4.9-3.9c-2.4,0-4.4,1.7-4.9,3.9h-4.1   c0-5-3.2-3.9-3.2-3.9c0-5.5,7.9-5.9,7.9-5.9s2.4-8.3,12.7-8.3s12.7,7.8,12.7,7.8C80.3,61.1,79.5,66.4,79.5,66.4z" />
        <path d="M56,55.2v6.3h10.4C66.4,61.5,63.5,54.3,56,55.2z" />
      </g>
    </svg>
  )
}
