// import React, { useEffect } from 'react'

// import { default as RCImageGallery } from 'react-image-gallery'
// import 'react-image-gallery/styles/css/image-gallery.css'

// const ImageGalleryCmp = RCImageGallery


// export const ImageGallery = (props) => {


//   useEffect(() => {
//     // set full .image-gallery-image equal with
//     const images = document.querySelectorAll('.image-gallery-image')
//     images.forEach((img) => {
//       img.style.height = img.clientWidth + 'px'
//     })
//   }, [props.items])


//   const onScreenChange = (fullScreenElement) => {
//     if (fullScreenElement) {
//       const images = document.querySelectorAll('.fullscreen .image-gallery-image')
//       images.forEach((img) => {
//         img.style.height = 'calc(100vh - 120px)'
//       })
//     }
//   }

//               {/* Nó sẽ trả về một mảng : galleries = [p1,p2 ]
//                 mà p1 = {original : "link", thumbnail : 'link'  }
//                 .. p2 = 
//                 props = {
//                   items : galleries,
//                   autoPlay,
//                 }
//                 */}
//   return <ImageGalleryCmp {...props} onScreenChange={onScreenChange} />
// }



//J:\data\website\web_nova\client_web\src\components\ImageGallery\index.jsx


import React, { useState, useEffect } from 'react';
import { default as RCImageGallery } from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import { useContext } from 'react';
import { Context } from "../../context_global/Context";
import { ControlOutlined } from '@ant-design/icons';
const ImageGalleryCmp = RCImageGallery;


export const ImageGallery = (props) => {
  const { imght, setimght } = useContext(Context);
  const [loadedImages, setLoadedImages] = useState(0);

  useEffect(() => {
    setLoadedImages(0);
    // set full .image-gallery-image equal with
    const images = document.querySelectorAll('.image-gallery-image');
    images.forEach((img) => {
      img.style.height = img.clientWidth + 'px';
    });
  }, [props.items]);

  const onScreenChange = (fullScreenElement) => {
    if (fullScreenElement) {
      const images = document.querySelectorAll('.fullscreen .image-gallery-image');
      images.forEach((img) => {
        img.style.height = 'calc(100vh - 120px)';
      });
    }
  };




  const handleCustomButtonClick = () => {
    console.log("8888888888")
    console.log(imght);
  };

  const handleImageLoad = () => {
   // console.log('xong Load------------------>', events.target.src);
    setLoadedImages(prev => prev + 1);
  };

  const totalImages = props.items.length;


  return (
    <div>
      {/* <button onClick={handleCustomButtonClick}></button> */}
      {/* Nó sẽ trả về một mảng : galleries = [p1,p2 ]
                mà p1 = {original : "link", thumbnail : 'link'  }
                .. p2 = 
                dữ liệu của props bên dưới :
                props = {
                  items : galleries,
                  autoPlay,
                }
                */}

      <ImageGalleryCmp
        {...props}
        onScreenChange={onScreenChange}
         onSlide={(index) => {
          //console.log('Chỉ số của hình ảnh đang hiển thị:', index);
          const imageName = props.items[index].original; // Thay 'original' bằng thuộc tính tên hình ảnh mà bạn muốn log
         // console.log('Tên của hình ảnh đang hiển thị:', imageName);
          setimght(imageName);

        }}

      />
      
      {props.items.map((src, index) => (
          <img
            key={index}
            src={src.original}
            onLoad={handleImageLoad}
            style={{ display: 'none' }} // Ẩn ảnh nếu bạn chỉ muốn kiểm tra load
          />
        ))}
      {/* {loadedImages === totalImages && (
        <div>All images have been loaded!</div>
      )} */}
    </div>
  );
};

export default ImageGallery;

