"use client";

import { useState } from "react";

export default function MyComponent() {


  return (

      <div style={{ display: 'flex', justifyContent: 'center' }}>


        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3922.5463249273744!2d107.53607629999999!3d10.536353599999998!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3175bd093a54e121%3A0x7978d923a6670223!2sHappy%20Stay%20Resort!5e0!3m2!1sen!2s!4v1723190518845!5m2!1sen!2s" width="600" height="450" style={{border :"0"}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        
      </div>

  );
}

